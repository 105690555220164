import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries, BarSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { useAssetPrice } from '../hooks/AssetPrice';
import {
	CrossHairCursor,
	MouseCoordinateX,
	MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";
import { scaleTime } from "d3-scale";
import { timeFormat } from "d3-time-format";
import { format } from "d3-format";
import { useMediaQuery } from 'react-responsive';
import useWindowDimensions from '../hooks/WindowDimensions';
import { ContentText, FONT_SIZE } from '../components/Typography';
import dayjs from 'dayjs';
import { Spinner } from '../components/Spinner';
import { Link } from 'react-router-dom';
import { DarkRoundedButton } from '../components/Buttons';
import up from "../static/up.png"
import down from "../static/down.png"
import { useMeasure } from "react-use";

const DashboardPriceGraphsWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: -0.5rem;
  flex-grow: 1;

  @media screen and (min-width: 768px) {
    margin-right: 10px;
  }
`;

const DashboardPriceGraphs = () => {
  const [selectedAssetIndex, setSeletedAssetIndex] = useState(0)

  return (
    <DashboardPriceGraphsWrapper>
        <Menu>
            <MenuItem isSelected={selectedAssetIndex==0} onClick={()=>{setSeletedAssetIndex(0)}}>ETH Stability Vault</MenuItem>
            <MenuItem isSelected={selectedAssetIndex==1} onClick={()=>{setSeletedAssetIndex(1)}}>ETH</MenuItem>
            <MenuItem isSelected={selectedAssetIndex==2} onClick={()=>{setSeletedAssetIndex(2)}}>BTC</MenuItem>
        </Menu>
        <ChartContainer>
            <Graph assetIndex={selectedAssetIndex}/>
        </ChartContainer>
    </DashboardPriceGraphsWrapper>
  );
};

const Graph = ({ assetIndex }) => {
    const [selectedGraphIndex, setSeletedGraphIndex] = useState(0)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const { width } = useWindowDimensions(); 
    const indexToAsset = {
        0: 'DOV',
        1: 'ETH',
        2: 'XBT'
    }

    const isStabilityVault = assetIndex == 0;

    const assetPrice = useAssetPrice(
        indexToAsset[assetIndex],
        dayjs().endOf('day').subtract(365*3, 'days').format(),
        dayjs().endOf('day').format(),
        isStabilityVault
    )

    const [data, dataIndexes] = useMemo(() => {
        if (!assetPrice) return [null, null];
        const d = assetPrice.map((e, i) => {
            return {
                date: new Date(Date.parse(e.time)),
                close: e.close,
                change: (i != 0) ? (assetPrice[i].close - assetPrice[i-1].close) / assetPrice[i-1].close : 0
            }
        })

        const ind = [
            d.findIndex(item => item.date >= dayjs().subtract(365*3, 'days')),
            d.findIndex(item => item.date >= dayjs().subtract(365, 'days')),
            d.findIndex(item => item.date >= dayjs().subtract(30, 'days'))
        ]
        return [d, ind]
    }, [assetPrice])

    const filteredData = useMemo(() => {
        if (!data || !dataIndexes) return null

        return data.slice(dataIndexes[selectedGraphIndex], -1)
    }, [data, dataIndexes, selectedGraphIndex])

    const chartContainerCallback = useCallback((ref) => {
        if (!ref) return
        ref.addEventListener('wheel', (e)=>{
            if (!e.ctrlKey) e.stopPropagation()
            else e.preventDefault()
        }, { passive: false });
    })

    const tickFormat = (!isStabilityVault) ? (d) => '$' + d : d => d;
    const [ref, {width: elWidth}] = useMeasure()
    
    return <Container>
        <LeftContainer ref={ref}>
            <GraphMenu setSelected={setSeletedGraphIndex} selected={selectedGraphIndex} />
            <ChartCanvasContainer ref={chartContainerCallback}>
            {filteredData ? <ChartCanvas width={isTabletOrMobile ? width - 60: elWidth - 100} height={420}
                margin={{ left: 50, right: 0, top:10, bottom: 0 }}
                data={filteredData}
                xScale={scaleTime()}
                xAccessor={d => d.date}
                ratio={2}
                type="hybrid"
                seriesName="ETH"
                clamp={true}
                pointsPerPxThreshold={1000}
                xExtents={[filteredData[0].date, filteredData[filteredData.length-1].date]}
                >
                <Chart id={1} yExtents={d=>[d.close * 0.9, d.close * 1.1]} width={650} height={300}>
                    <XAxis axisAt="bottom" orient="bottom" ticks={4} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={11} tickStroke="rgba(0,0,0,0.6)" stroke="rgba(0,0,0,0.6)"/>
                    <YAxis axisAt="left" orient="left" ticks={7} innerTickSize={0} tickFormat={tickFormat} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={11} tickStroke="rgba(0,0,0,0.6)"/>
                    <MouseCoordinateX
                        at="bottom"
                        orient="bottom"
                        fontSize={11}
                        displayFormat={timeFormat("%Y-%m-%d")} />
                    <MouseCoordinateY
                        at="left"
                        orient="left"
                        arrowWidth={0}
                        fontSize={11}
                        displayFormat={format(">.2f")} />
                    <AreaSeries yAccessor={d => d.close} strokeWidth={1} fill="#F7FAFC" stroke="#OAOAOA"/>
                </Chart>
                <Chart id={2} yExtents={[-0.1, 0.1]} width={650} height={40} origin={[0, 350]}>
                    <BarSeries yAccessor={d => d.change} fill="#OAOAOA" baseAt={20}/>
                    <YAxis axisAt="left" orient="left" ticks={2} showDomain={false} tickFormat={format(".0%")} percentScale={true} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={10} innerTickSize={0} tickStroke="rgba(0,0,0,0.6)"/>
                </Chart>
                <CrossHairCursor />
            </ChartCanvas>: <Spinner />}
            </ChartCanvasContainer>
        </LeftContainer>
        <HistoricalInfo data={data} dataIndex={assetIndex}/>
    </Container>
}

const LeftContainer = styled.div`
flex: 1;
`

const GraphMenu = ({ selected, setSelected }) => {
    return (
        <GraphMenuContainer>
            <GraphMenuOption text="All Time" index={0} selected={selected} setSelected={setSelected} />
            <GraphMenuOption text="Year" index={1} selected={selected} setSelected={setSelected} />
            <GraphMenuOption text="3M" index={2} selected={selected} setSelected={setSelected} />
        </GraphMenuContainer>
    )
}

const GraphMenuOption = ({ text, selected, setSelected, index}) => {
    const isSelected = selected == index
    return (
        <GraphMenuItemButton isSelected={isSelected} onClick={() => {setSelected(index)}}>{text}</GraphMenuItemButton>
    )
}

const HistoricalInfo = ({ data, dataIndex }) => {
    const dataSlices = useMemo(() => {
        if (!data) return;
  
        const indexes = [
            0,
            data.findIndex(item => item.date >= dayjs().subtract(365, 'days')),
            data.findIndex(item => item.date >= dayjs().subtract(90, 'days'))
        ]
        return indexes.map((e) => data.slice(e))
    }, [data])


    const returns = useMemo(() => {
        if (!dataSlices) return null
    
        return dataSlices.map(d => (d[d.length-1].close - d[0].close) /d[0].close * 100).map(e => e.toFixed(1))
    }, [dataSlices])

    if (!returns) return <></>

    return <HistoricalContainer showButton={dataIndex == 0}>
        {dataIndex == 0 && <Link to="/marketplace">
            <MarketplaceCTA>Invest in ETH Stability Vault</MarketplaceCTA>
        </Link>}
        <Card name = "3 Months" description="3 months change in price" val={returns[2]} />
        <Card name = "Year" description="One year change in price" val={returns[1]} />
        <Card name = "All time" description="All time change in price" val={returns[0]} />
    </HistoricalContainer>
}

const Card = ({ name, description, val }) => {
    return <CardContainer>
        <CardLeft>
            <CardTitle>{name}</CardTitle>
            <CardDescription>{description}</CardDescription>
        </CardLeft>
        <CardRight>
            <CardValue>{val}%</CardValue>
            <IndicatorImg src={val >= 0 ? up : down}/>
        </CardRight>
    </CardContainer>
}

export default DashboardPriceGraphs;

const Container = styled.div`
display: flex;
flex-direction: column;
@media screen and (min-width: 768px) {
    flex-direction: row;
}
`

const ChartCanvasContainer = styled.div`
width: ${props=>props.width};
margin-right: 1rem;
`;

const Menu = styled.div`
border-radius: 10px;
padding: 0 0.5rem;
background-color: #F7FAFC;
@media screen and (min-width: 768px) {
    padding: 0 1rem;
}
`

const MenuItem = styled.button`
text-decoration: none;
border: none;
background: none;
font-size: ${FONT_SIZE.small};
font-weight: ${props=>props.isSelected ? 700 : 500};
cursor: pointer;
color: black;
margin-top: 1rem;
margin-right: 1rem;
:after {
  content: "";
  display: block;
  margin-top: 0.8rem;
  right: 0;
  width: 100%;
  height: 3px;
  background: ${props => props.isSelected ? `rgba(0,0,0, 0.8)`: 'transparent'};
}
@media screen and (min-width: 768px) {
    font-size: ${FONT_SIZE.regular};
    margin-right: 1.5rem;
}
`

const ChartContainer = styled.div`
touch-action: pan-y;
margin-top: 2rem;
`


const GraphMenuContainer = styled.div`
display: flex;
width: calc(100% - 3rem);
border-bottom: 1px solid rgba(0,0,0,0.2);
margin-bottom: 1rem;
margin-left: 0rem;
@media screen and (min-width: 768px) {
    width: calc(100% - 2rem);
    margin-left: 1rem;
}
`

const GraphMenuItemButton = styled.button`
background: none;
border: none;
cursor: pointer;
font-size: ${FONT_SIZE.regular};
padding: 0;
color: #0A0A0A;
margin-right: 2rem;
margin-bottom: 0;
:after {
  content: "";
  display: block;
  margin-top: 0.8rem;
  right: 0;
  width: 100%;
  height: 3px;
  background: ${props => props.isSelected ? `rgba(0,0,0, 0.8)`: 'transparent'};
}
`

const CardContainer = styled.div`
display: flex;
border-left: 4px solid #1B1B1B;
padding-left: 1.5rem;
:not(:last-child) {
    margin-bottom: 4rem;
}
`

const HistoricalContainer = styled.div`
margin-top: 2rem;

@media screen and (min-width: 768px) {
    margin-top: ${props=>props.showButton ? 0: '4rem'};
    margin-left: 2rem;
    margin-right: 2rem;
}

`

const CardTitle = styled(ContentText)`
font-size: 1.5rem;
font-weight: 700;
color: #1B1B1B;
`

const CardDescription = styled(ContentText)`
font-size: 0.9rem;
font-weight: 300;
margin-top: 0.5rem;
`

const CardLeft = styled.div`
width: 10rem;
`

const CardRight = styled.div`
display: flex;
align-items: center;
`

const CardValue= styled(ContentText)`
margin-left: 4rem;
font-size: 1.6rem;
font-weight: 700;
color: #1B1B1B;
`

const IndicatorImg = styled.img`
width: 24px;
height: 24px;
margin-left: 5px;
`

const MarketplaceCTA = styled(DarkRoundedButton)`
margin-bottom: 2rem;
font-size: 1rem;
border-radius: 18px;

@media screen and (min-width: 768px) {
    margin-bottom: 4rem;
}
`