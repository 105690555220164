import './App.css';	
import Dashboard from './new-pages/Dashboard';	
import { BrowserRouter, Routes, Route } from "react-router-dom";	
import { LandingPage } from './pages/NewLandingPage';	
import { GeofencePage } from './pages/Geofence';
import Marketplace from './new-pages/Marketplace';
import { init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import { useReducer } from 'react';
import { MainContext, DispatchContext } from './Context';

const injected = injectedModule()

const infuraKey = '445387178c3d470a9ed802466bd00bdc'
const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`

// initialize Onboard, only need to do this once in entire web app
init({
  wallets: [injected],
  chains: [
    {
      id: '0x89',
      namespace: 'evm',
      token: 'MATIC',
      label: 'Polygon',
      rpcUrl: 'https://polygon-rpc.com'
    }
  ],
  appMetadata: {
    name: "Dovish Finance",
    description: "Dovish Finance",
    icon: `<svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"></svg>`,
  },
  accountCenter: {
    mobile: {
      enabled: false
    },
    desktop: {
      enabled: false
    }
  }
})

function App() {	
  const [context, dispatch] = useReducer(
    reducer,
    { notUsa: false}
  );

  return (	
    <div className="App">
      <MainContext.Provider value={context}>
        <DispatchContext.Provider value={dispatch}>
          <BrowserRouter>	
              <Routes>
              <Route exact path="/" element={<LandingPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/marketplace" element={<Marketplace/>} />	
              <Route path="/geofence" element={<GeofencePage/>} />	
              </Routes>	
          </BrowserRouter>	
        </DispatchContext.Provider>
      </MainContext.Provider>
    </div>	
  );	
}	
export default App;	


const reducer = (tasks, action) => {
  switch(action.type) {
    case "agree_not_usa":
      return {
        notUsa: true
      }
    default:
      return {
        notUsa: false
      }
  }
}