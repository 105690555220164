import styled from "styled-components/macro"
import graphic from "../../static/graphic-1.png"

export function GeofenceInformation() {
    return (
        <InformationSegmentContainer>
            <TextContainer>
                <SubTitle>Looks like you're connecting from a US/SVG IP address.</SubTitle>
                <SubSubTitle>
                    Disclaimer regarding the Tokens Restricted for Restricted Persons
                </SubSubTitle>
                <Body>
                You shall not purchase or otherwise acquire any of our token products if you are: a citizen, resident (tax or otherwise), and/or green card holder, 
                incorporated in, owned or controlled by a person or entity in, located in, or have a registered office or principal place of business in the U.S. 
                (defined as a U.S. person), or if you are a person in any jurisdiction in which such offer, sale, and/or purchase of any of our token products is unlawful, 
                prohibited, or unauthorized (together with U.S. persons, a “Restricted Person”).  The term “Restricted Person” includes, but is not limited to, any natural person residing in, 
                or any firm, company, partnership, trust, corporation, entity, government, state or agency of a state, or any other incorporated or unincorporated body or association, 
                association or partnership (whether or not having separate legal personality) that is established and/or lawfully existing under the laws of, a jurisdiction in which such offer, 
                sale, and/or purchase of any of our token products is unlawful, prohibited, or unauthorized). 
                None of our token products have been or will be registered under the U.S. Securities Act of 1933, as amended, or with any securities regulatory authority of any state or 
                other jurisdiction of the U.S.  Our token products may not be offered, sold, or delivered within the U.S. to, or for the account or benefit of, Restricted Persons.  
                Our token products that may be offered on secondary markets and other platforms are not for distribution to any Restricted Person.  No offers, sales, resales, or deliveries 
                of any of our token products may be made in or from any jurisdiction (including the U.S.), except in circumstances that will result in compliance with any applicable laws and 
                regulations and that will not impose any obligations on Dovish Finance.  Persons who obtain our token products are required to inform themselves about and adhere to any such restrictions.  
                Dovish Finance reserves the right to impose further restrictions at its sole discretion, which will be communicated through its terms of service or on its website.
                </Body>
            </TextContainer>
            <Image src={graphic} height="50%"/>
        </InformationSegmentContainer>
    )
}

const InformationSegmentContainer = styled.div`
display: flex;
padding: 2rem;
height: 60vh;
position: relative;
align-items: center;
@media screen and (max-width: 768px) {
    height: 100%;
}
`;

const TextContainer = styled.div`
width: 60vw;
@media screen and (max-width: 768px) {
    width: 100%;
}
`

const Title = styled.h1`
text-align: left;
`;

const SubTitle = styled.h2`
text-align: left;
`

const SubSubTitle = styled.h3`
text-align: left;
font-style: italic;
`

const Body = styled.h3`
text-align: left;
font-style: italic;
`

const Image = styled.img`
position: absolute;
right: 2rem;
@media screen and (max-width: 768px) {
    display: none;
}
`