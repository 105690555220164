import styled from "styled-components/macro"
import { FONT_SIZE } from "../Typography"
import { DarkRoundedButton } from "../Buttons"
import { useMediaQuery } from "react-responsive"
import bob from "../../static/bob.png"

export function Hero() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return <Container>
      <MainContent>
        <BobImg src={bob}/>
        <MainText>
            {isTabletOrMobile && <Headline> Bringing smart money<br/> to DeFi </Headline>}
            {!isTabletOrMobile && <Headline> Bringing smart money<br/> to DeFi </Headline>}
            <Subtitle>Trillions of dollars are already managed by quant models in TradFi.<br />Start investing better through proven strategies on DeFi with Dovish.</Subtitle>
        </MainText>
        <StartButton forwardedAs="a" href="/marketplace" className="umami--click--start-button">Get Started</StartButton>
      </MainContent>
    </Container>
}

const Container = styled.div`
position: relative;
overflow: none;
text-align: left;
`

const MainContent = styled.div`
position: relative;
margin-top: 1rem;
width: 100%;
max-width: 1340px;
margin-left: auto;
margin-right: auto;
padding-bottom: 3rem;
background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.6) -14.67%, rgba(255, 255, 255, 0) 91.34%), radial-gradient(64.65% 84.71% at 39.38% 34.68%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), conic-gradient(from 203.21deg at 40.68% 110.4%, #EEEDFD 0deg, #D2FBF1 360deg);
border-radius: 40px;
@media screen and (min-width: 768px) {
  padding-bottom: 5rem;
  width: calc(100% - 10rem);
}
`

const MainText = styled.div`
animation: 0.65s ease-in 0s 1 slideInBottom;
`

const Headline = styled.h1`
position: relative;
z-index: 10;
font-size: ${FONT_SIZE.large};
font-weight: 700;
padding: 0;
margin: 0;
margin-left: 1.5rem;
padding-top: 9rem;
@media screen and (min-width: 768px) {
  width: 60%;
  padding-top: 10rem;
  margin-left: 4rem;
  font-size: ${FONT_SIZE.extraLarge};
}
`

const Subtitle = styled.h3`
padding-top: 0;
margin: 2rem 3rem 0 1.5rem;
font-weight: 400;
line-height: 180%;
font-size: ${FONT_SIZE.regular};
@media screen and (min-width: 768px) {
  font-size: 1.25rem;
  margin-top: 3rem;
  margin-left: 4rem;
  width: 55ch;
  font-size: ${FONT_SIZE.medium};
}
`

const StartButton = styled(DarkRoundedButton)`
display: inline-block;
margin-top: 3rem;
margin-left: 1.5rem;
text-decoration: none;
opacity: 0;
animation: 0.65s ease-in 0.5s 1 fadeIn both;
@media screen and (min-width: 768px) {
  margin-left: 4rem;
  margin-top: 3.2rem;
}
`

const BobImg = styled.img`
@keyframes bobAnimation {
  0% {
    transform: translateY(-8%);
    opacity: 0%;
  }
  50% {
    transform: translateY(2%);
    opacity: 50%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
position: relative;
float: right;
z-index: 1;
top: 0;
height: 12rem;
border-radius: 0 40px 0 0;
animation: 0.5s linear 0.5s 1 both bobAnimation;
@media screen and (min-width: 768px) {
    height:24rem;
}
`