import { useState, useEffect } from "react";

export function useStrategyData() {
    const [strategyData, setStrategyData] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            const res = await fetch("https://dovish.finance/api/benchmark/");
            const data = await res.json();
            setStrategyData(data);
        }
        
        fetchData();
    }, [])

    return strategyData;
}