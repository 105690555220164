import styled from "styled-components/macro"
import logo from "../../static/dovish-logo.png"
import menu from "../../static/menu.svg";
import { COLORS } from "../Colors";
import { useMediaQuery } from "react-responsive";
import { DarkRoundedButton } from "../Buttons";
import { FONT_SIZE } from "../Typography";

export function Navbar({ setShowMenu }) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return <Container>
        <MainContent>
            <Logo src={logo} alt="Logo" />
            {isTabletOrMobile && <MenuButton alt="menu" onClick={ () => {setShowMenu(true)} }/>}
            {!isTabletOrMobile && <DesktopMenu />}
        </MainContent>
    </Container>
}

const DesktopMenu = () => {
    return <div>
        <Item href="#stability-vault" className="umami--click--stability-nav">Stability Vault</Item>
        <Item href="https://swap.dovish.finance" className="umami--click--swap-nav">Swap</Item>
        <Item href="/#faq" className="umami--click--faq-nav">FAQ</Item>
        <Item href="https://docs.dovish.finance" className="umami--click--docs-nav">Docs</Item>
        <StartButton forwardedAs="a" href="/marketplace" className="umami--click--start-button">Get Started</StartButton>
    </div>
}

const Container = styled.div`
animation: 0.65s ease-in 0s 1 slideInTop;
position: sticky;
top: 0;
z-index: 20;
background-color: white;
height: 3.5rem;
width: 100%;
flex-shrink: 0;
@media screen and (min-width: 768px) {
    height: 4rem;
}
`

const MainContent = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
position: relative;
width: calc(100% - 3rem);
height: 100%;
max-width: 1340px;
margin-left: auto;
margin-right: auto;
@media screen and (min-width: 768px) {
  width: calc(100% - 10rem);
}
`

const Logo = styled.img`
height: 22px;
@media screen and (min-width: 768px) {
    height: 25px;
}
`

const MenuButton = styled.button`
background-image: url(${menu});
background-position: center;
background-color: transparent;
height: 1rem;
width: 1rem;
border: none;
cursor: pointer;
`

const Item = styled.a`
margin-left: 2rem;
text-decoration: none;
color: ${COLORS.dark};
font-size: ${FONT_SIZE.small};
`

const StartButton = styled(DarkRoundedButton)`
display: inline-block;
text-decoration: none;
background-color: transparent;
color: ${COLORS.dark};
border: 2px solid ${COLORS.dark};
margin-left: 2rem;
padding: 0.8rem 1.5rem;
font-size: ${FONT_SIZE.small};
`
