import styled from "styled-components/macro"
import { ContentText, FONT_SIZE, SegmentHeading } from "../Typography"
import { COLORS } from "../Colors"
import trend from "../../static/trending-up.svg"
import bannerImg from "../../static/overview.png"
import { StrategyGraph } from "./StrategyGraph"
import { useInView } from 'react-intersection-observer';

export function Overview() {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    return <Container>
        <MainContent>
            <Anchor id="about"/>
            <DescriptionContainer>
                <Neutral src={bannerImg} />
                <SegmentWrapper>
                    <Heading>Dovish aims to make risk management simple for crypto</Heading>
                    <MainDescription>
                    Risk management has enabled top quant funds to generate up to 38% annual returns even in bear markets. Get access to this level of performance in DeFi through Dovish.
                    </MainDescription>
                </SegmentWrapper>
            </DescriptionContainer>
            <FeatureList ref={ref}>
                <FeatureItem
                title="Optimize for dollars"
                description="Your profits will only be in USDC. Unlike other protocols, we do not rely on complex emissions and token incentives to conceal the actual yield."
                icon={trend}
                idx={0}
                inView={inView}
                />
                <FeatureItem
                title="Liquid and Secure"
                description="Our vaults are redeemable at any time, and have been audited for protection against security exploits."
                icon={trend}
                idx={1}
                inView={inView}
                />
                <FeatureItem
                title="Win long term"
                description="Our strategies focus on long-term success by capturing the overall upward trend in the crypto market while minimizing exposure to major downturns."
                icon={trend}
                idx={2}
                inView={inView}
                />
            </FeatureList>
        </MainContent>
    </Container>
}

const FeatureItem = ({ title, description, icon, idx, inView }) => {
    return <FeatureContainer idx={idx} inView={inView}>
        <Circle>
            <FeatureIcon src={icon} />
        </Circle>
        <FeatureTitle>{title}</FeatureTitle>
        <FeatureText>{description}</FeatureText>
    </FeatureContainer>
}

const Container = styled.div`
animation: 0.65s ease-in 0.75s 1 fadeIn both;
padding: 2rem 0;
text-align: left;
margin-bottom: 2rem;
background-color: ${COLORS.white};
@media screen and (min-width: 768px) {
    padding: 3rem 0;
}
`

const MainContent = styled.div`
position: relative;
width: calc(100% - 3rem);
max-width: 1340px;
margin-left: auto;
margin-right: auto;
@media screen and (min-width: 768px) {
  width: calc(100% - 10rem);
}
`

const Heading = styled(SegmentHeading)`
max-width: 95%;
margin-top: 3rem;
margin-right: 2rem;
@media screen and (min-width: 768px) {
  max-width: 27ch;
  margin-top: 0;
}
`

const MainDescription = styled(ContentText)`
margin-top: 2.5rem;
line-height: 190%;
max-width: 90%;
@media screen and (min-width: 768px) {
    margin-top: 3rem;
    max-width: 50ch;
}
`

const Neutral = styled.img`
width: 100%;
max-width: 32rem;
height: auto;
@media screen and (min-width: 768px) {
    width: 45%;
    margin-right: 2rem;
}
`;

const FeatureList = styled.div`
margin-top: 5rem;
@media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const FeatureContainer = styled.div`
flex: 1;
margin-top: 2.5rem;
padding: 0;
opacity: 0;
width: 90%;
animation: ${props => props.inView && `0.5s ease-in ${props.idx*0.3}s 1 fadeIn both`};

@media screen and (min-width: 768px) {
    padding: 2rem 0;
    animation: ${props => props.inView && `0.5s ease-in ${props.idx*0.3}s 1 slideInBottom both`};
    :not(:last-child) {
        padding-right: 3rem;
    }
}
`

const FeatureTitle = styled(SegmentHeading)`
margin-top: 0.8rem;
`

const FeatureText = styled(ContentText)`
margin-top: 2rem;
line-height: 180%;
`

const Circle = styled.div`
position: relative;
height: 2.2rem;
width: 2.2rem;
border-radius: 1.3rem;
border: 3px solid transparent;
background-image: linear-gradient(to bottom right, #EEEDFD, #D2FBF1);
`

const FeatureIcon = styled.img`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
height: 1.3rem;
width: 1.3rem;
border-radius: 0.75rem;
font-size: 2em;
color: ${COLORS.dark};
`

const Anchor = styled.span`
position: relative;
top: -5rem;
`

const SegmentWrapper = styled.div`
    display: flex;
    margin-right: 1rem;
    flex-direction: column;
    justify-content: space-between;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

