import styled from "styled-components"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useMemo } from "react";
import { COLORS } from "../Colors";

export function StrategyGraph({ rawData, color, className }) {
    ChartJS.register(
        CategoryScale,
        TimeScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );

    const options = {
        responsive: true,
        pointRadius: 1,
        maintainAspectRatio: false,
        animation: {
            duration: 0
        },
        scales: {
            y: {
                type: 'linear',
                grid: {
                    display: true
                },
                title: {
                    display: false,
                },
                ticks: {
                    font: {
                        family: 'Lato, sans-serif',
                        weight: 500,
                        size: 11
                    },
                    color
                }
            },
            x: {
                type: 'time',
                time: {
                    unit: 'month'
                },
                title: {
                    display: false,
                    text: 'Date'
                },
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: 'Lato, sans-serif',
                        weight: 500,
                        size: 10
                    },
                    maxTicksLimit: 10,
                    color
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "",
            }
        }
    };


    const datasets = useMemo(() => {
        if (!rawData) return [];
        const data = rawData.values;
        return [
            {
                fill: '+1',
                label: 'ETH Stability Vault',
                data: (data !== null) ? Object.keys(data['strategy']).map((key) => {
                    return {
                        x: new Date(parseInt(key)),
                        y: data['strategy'][key]
                    };
                }) : [],
                backgroundColor: 'transparent',
                borderColor: COLORS.gray,
                borderWidth: 2,
                pointStyle: "line",
                tension: 0.4         
            },
            {
                fill: 'origin',
                label: 'Holding ETH',
                data: (data !== null) ? Object.keys(data['benchmark']).map((key) => {
                    return {
                        x: new Date(parseInt(key)),
                        y: data['benchmark'][key]
                    };
                }): [],
                backgroundColor: 'transparent',
                borderColor: COLORS.light,
                borderWidth: 2,
                pointStyle: "line",
                tension: 0.4
            }
        ];
    }, [rawData]);

    if (!rawData) return <></>

    return (
        <Container className={className}>
            {/* <Headline>Performance vs. wETH</Headline> */}
            <Line options={options} data={{ datasets }} />
        </Container>
    )
}

const Container = styled.div`
height: 20rem;
margin-top: 3rem;
`

const Headline = styled.h1`
font-size: 1.2em;
font-weight: 800;
text-align: left;
/* @media screen and (max-width: 768px) {
    margin-left: 1rem;
} */
`