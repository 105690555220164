import styled from 'styled-components'
import { Color } from '../utils/Color'

export function Footer() {
    return (
        <FooterContainer>
            © Copyright 2023 Lithium Labs LLC
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
/* border-top: 1px solid rgba(0, 0, 0, 0.2); */
margin: 1.5rem;
/* padding-top: 2rem; */
padding-left:0.5rem;
padding-bottom: 0.5rem;
text-align: left;

@media screen and (max-width: 768px) {
    margin: 1rem;
    padding-left: 0;
}
`