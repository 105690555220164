const COLORS = {
    dark: "#212121",
    white: "#ffffff",
    offWhite: "F9F9F9",
    gray: "#888888",
    light: "#DBDBDB",
    primary: "#00A67E",
    primaryBright: "#08CF9F",
}

const GRADIENTS = {
    primary: "linear-gradient(to bottom right, #EEEDFD, #D2FBF1)"
}

export { COLORS, GRADIENTS }
