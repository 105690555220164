import { useState, useEffect } from "react";

export function useAssetPrice(asset, startTime, endTime, isStabilityVault = false) {
    const [assetPrice, setAssetPrice] = useState(null);

    useEffect(()=>{
        setAssetPrice(null)

        const fetchData = async () => {
            if (isStabilityVault) {
                const res = await fetch("https://dovish.finance/api/benchmark/");
                const data = await res.json();
                setAssetPrice(Object.entries(data.values.strategy).map(([key, val]) => {
                    return {
                        time: new Date(parseInt(key)),
                        close: val,
                    }
                }))
            } else {
                const res = await fetch("https://dovish.finance/api/prices/?"+ new URLSearchParams({ asset, startTime, endTime }));
                const data = await res.json();
                setAssetPrice(data);
            }
        }
        
        fetchData();
    }, [asset, startTime, endTime, isStabilityVault])

    return assetPrice;
}