import React, { useState } from 'react';
import styled, {css} from "styled-components/macro";
import { FONT_SIZE, SegmentHeading } from "../Typography";
import { COLORS } from "../Colors";
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';

const Data = [
    {
      question: 'Where are these signals coming from?',
      answer: 'Our team has built data pipelines from all across crypto, ranging from liquidity pools, loan pools, to crypto option prices. This data has enabled our quant teams to build out strong predictive signals that have been helpful in improving our strategies’ performance!'
    },
    {
      question: 'How much does Dovish cost?',
      answer: 'Dovish charges a 2% management fee, and a 0.5% exit fee. There is no entry fee to our vaults so as to encourage our users to dollar cost average into our vaults over time!'
    },
    {
      question: 'What are the risks associated with Dovish?',
      answer: 'Historical backtesting of financial strategies do not always directly translate into future gains. The APY seen from our graphs is not a lower or upper bound on our performance.'
    }

];
export function Faq() {
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if (clicked === index) {
        //if clicked question is already active, then close it
        return setClicked(null);
        }

        setClicked(index);
    };
    return <Container>
          <MainContent>
            <Anchor id="faq"/>
            <SegmentHeading>FAQ</SegmentHeading>
            <IconContext.Provider value={{ color: '#000000', size: '25px' }}>
                <AccordionSection>
                    {Data.map((item, index) => {
                        return (
                        <>
                            <Wrap onClick={() => toggle(index)} key={index} className="umami--click--faq">
                            <h1>{item.question}</h1>
                            <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                            </Wrap>
                            {clicked === index ? (
                              <Dropdown style={{maxHeight: "100px"}}>
                                <p>{item.answer}</p>
                              </Dropdown>
                            ) : (
                              <Dropdown> 
                                <p>{item.answer}</p>
                              </Dropdown>
                            )}
                        </>
                        );
                    })}
                </AccordionSection>
            </IconContext.Provider>
      </MainContent>
    </Container>
}


const Container = styled.div`
padding: 3rem 0;
text-align: left;
background-color: ${COLORS.offWhite};
text-align: left;
@media screen and (min-width: 768px) {
  padding: 5rem 0;
}
`

const MainContent = styled.div`
position: relative;
width: calc(100% - 3rem);
max-width: 1340px;
margin-left: auto;
margin-right: auto;
@media screen and (min-width: 768px) {
  width: calc(100% - 10rem);
}
`

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* height: 60vh; */
`;

const Wrap = styled.div`
  color: #000000;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  text-align: left;
  cursor: pointer;

  h1 {
    padding: 2rem 0;
    margin: 0;
    margin-right: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  span {
    margin-top: 2.1rem;
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: 1.5rem;
    }
  }
`;

const Anchor = styled.span`
position: relative;
top: -5rem;
`

// const Dropdown = styled.div`
//   color: #000000;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   /* border-bottom: 1px solid #00ffb9; */
//   /* border-top: 1px solid #00ffb9; */
//   max-height: 0; /* added this */
//   overflow: hidden; /* added this */
//   transition: max-height 0.5s ease-out; /* added this */
  
//   p {
//     font-size: ${FONT_SIZE.small};
//     width: 100%;
//   }
// `;

const Dropdown = styled.div`
  color: #000000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;

  ${({ open }) =>
    open &&
    css`
      max-height: 100px;
    `};

  p {
    font-size: ${FONT_SIZE.regular};
    width: 100%;
  }
`;
