import styled from 'styled-components';

const Button = styled.button`
  position: absolute;
  text-align: center;  
  justify-content: center; 
  top: 30%;
  width: 10%;
  right: 45%;
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 5px;
  border-radius: 5px;
  margin: 10px 0px;
  z-index: 2;
  cursor: pointer;
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
  }
`;

const ButtonApp = styled.button`
  position: relative;
  float: right;
  top: 1%;
  width: 10%;
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 5px;
  border-radius: 5px;
  margin: 10px 0px;
  z-index: 3;
  cursor: pointer;
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
  }
`;

const LaunchButtonApp = styled.button`
  position: relative;
  float: left;
  top: 1%;
  width: 25%;
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 5px;
  border-radius: 5px;
  margin: 10px 0px;
  z-index: 3;
  cursor: pointer;
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
  }
`;



export function Profile() {
  // const { chain, chains } = useNetwork()
  // const { address, connector, isConnected } = useAccount()
  // const { data: ensName } = useEnsName({ address })
  // const { connect, connectors, error, isLoading, pendingConnector } =
  //   useConnect()
  // const { disconnect } = useDisconnect()

  // if (isConnected) {
  //   return (
  //     <div>
  //       <div>{ensName ? `${ensName} (${address})` : address}</div>
  //       <div>Connected {chain.name}</div>
  //       <Button onClick={disconnect}>Launch app</Button>
  //     </div>
  //   )
  // }

  // return (
  //   <div>
  //     {connectors.map((connector) => (
  //       <Button
  //         disabled={!connector.ready}
  //         key={connector.id}
  //         onClick={() => connect({ connector })}
  //       >
  //         Launch app
  //         {!connector.ready && ' (unsupported)'}
  //         {isLoading &&
  //           connector.id === pendingConnector?.id &&
  //           ' (connecting)'}
  //       </Button>
  //     ))}

  //     {error && <div>{error.message}</div>}
  //   </div>
  // )
}

export function Wallet() {
  // const { chain, chains } = useNetwork()
  // const { address, connector, isConnected } = useAccount()
  // const { data: ensName } = useEnsName({ address })
  // const { connect, connectors, error, isLoading, pendingConnector } =
  //   useConnect()
  // const { disconnect } = useDisconnect()

  // if (isConnected) {
  //   return (
  //     <div>
  //       <ButtonApp onClick={disconnect}>{chain.name}</ButtonApp>
  //     </div>
  //   )
  // }

  // return (
  //   <div>
  //     {connectors.map((connector) => (
  //       <ButtonApp
  //         disabled={!connector.ready}
  //         key={connector.id}
  //         onClick={() => connect({ connector })}
  //       >
  //         Launch app
  //         {!connector.ready && ' (unsupported)'}
  //         {isLoading &&
  //           connector.id === pendingConnector?.id &&
  //           ' (connecting)'}
  //       </ButtonApp>
  //     ))}

  //     {error && <div>{error.message}</div>}
  //   </div>
  // )
}


export function WalletLanding() {
  // const { chain, chains } = useNetwork()
  // const { address, connector, isConnected } = useAccount()
  // const { data: ensName } = useEnsName({ address })
  // const { connect, connectors, error, isLoading, pendingConnector } =
  //   useConnect()
  // const { disconnect } = useDisconnect()

  // if (isConnected) {
  //   return (
  //     <div>
  //       <LaunchButtonApp onClick={disconnect}>{chain.name}</LaunchButtonApp>
  //     </div>
  //   )
  // }

  // return (
  //   <div>
  //     {connectors.map((connector) => (
  //       <LaunchButtonApp
  //         disabled={!connector.ready}
  //         key={connector.id}
  //         onClick={() => connect({ connector })}
  //       >
  //         Launch app
  //         {!connector.ready && ' (unsupported)'}
  //         {isLoading &&
  //           connector.id === pendingConnector?.id &&
  //           ' (connecting)'}
  //       </LaunchButtonApp>
  //     ))}

  //     {error && <div>{error.message}</div>}
  //   </div>
  // )
}