import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries, BarSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { scaleTime } from "d3-scale";
import { timeFormat } from "d3-time-format";
import { format } from "d3-format";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import dayjs from 'dayjs';
import {
	CrossHairCursor,
	MouseCoordinateX,
	MouseCoordinateY,
    EdgeIndicator
} from "react-stockcharts/lib/coordinates";
import React from 'react'
import styled from "styled-components/macro";
import { COLORS } from "./Colors";
import { ContentText, FONT_SIZE } from "./Typography";
import { useMediaQuery } from "react-responsive";
import useWindowDimensions from "../hooks/WindowDimensions";
import { Spinner } from "./Spinner";

export default function StrategyChart({ data, parentWidth }) {
    const [selectedGraphIndex, setSeletedGraphIndex] = useState(0)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const { width } = useWindowDimensions(); 

    const pdata = useMemo(() => {
        if (!data) return null
        return Object.entries(data.values.strategy).map(([key, val], i, arr) => {
            return {
                date: new Date(parseInt(key)),
                strategy: val,
                benchmark: data.values.benchmark[key],
                change: (i != 0) ? (val - arr[i-1][1])/arr[i-1][1] : 0
            }
        });
    }, [data]) 

    const dataIndexes = useMemo(() => {
        if (!pdata) return
        return [
            pdata.findIndex(item => dayjs(item.date) >= dayjs().subtract(365*5, 'days')),
            pdata.findIndex(item => dayjs(item.date) >= dayjs().subtract(365, 'days')),
            pdata.findIndex(item => dayjs(item.date) >= dayjs().subtract(30, 'days')),
        ]
    }, [pdata])

    const filteredData = useMemo(() => {
        if (!pdata || !dataIndexes) return null
        return pdata.slice(dataIndexes[selectedGraphIndex], -1)
    }, [pdata, dataIndexes, selectedGraphIndex])

    const chartContainerCallback = useCallback((ref) => {
        if (!ref) return
        ref.addEventListener('wheel', (e)=>{
            if (!e.ctrlKey) e.stopPropagation()
            else e.preventDefault()
        }, { passive: false });
    })

    
    return (
    <Container>
        <ChartContainer>
            <GraphMenu setSelected={setSeletedGraphIndex} selected={selectedGraphIndex} />
            {isTabletOrMobile && <MobileLegend/>}
            {filteredData ? <ChartCanvasContainer ref={chartContainerCallback}>
                    <ChartCanvas width={isTabletOrMobile ? width - 50 : parentWidth - 20} height={430}
                    // plotFull={true}
                    margin={{ left: 48, right: (isTabletOrMobile) ? 0 : 90, top:10, bottom: 30 }}
                    data={filteredData}
                    ratio={2}
                    type="svg"
                    seriesName="ETH"
                    clamp={true}
                    // zoomEvent={false}
                    // panEvent={false}
                    pointsPerPxThreshold={1000}
                    xExtents={[filteredData[0].date, filteredData[filteredData.length-1].date]}
                    xAccessor={d => d.date} xScale={scaleTime()}>
                        <Chart id={1} yExtents={d=>[0, Math.max(d.strategy, d.benchmark) * 1.1]} height={300}>
                            <XAxis axisAt="bottom" orient="bottom" ticks={4} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={11} tickStroke="rgba(0,0,0,0.6)" stroke="rgba(0,0,0,0.6)"/>
                            <YAxis axisAt="left" orient="left" ticks={7} innerTickSize={0} tickFormat={d=>"$"+d} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={11} tickStroke="rgba(0,0,0,0.6)"/>
                            <MouseCoordinateX
                                at="bottom"
                                orient="bottom"
                                fontSize={11}
                                displayFormat={timeFormat("%Y-%m-%d")} />
                            <MouseCoordinateY
                                at="left"
                                orient="left"
                                arrowWidth={0}
                                fontSize={11}
                                displayFormat={format(">.2f")} />
                            <AreaSeries yAccessor={d => d.strategy} fill="#F7FAFC" strokeWidth={1}/>
                            <AreaSeries yAccessor={d => d.benchmark} fill="rgba(0,0,0,0)" stroke="rgba(0,0,0,0.5)" />
                            {!isTabletOrMobile && <EdgeIndicator yAccessor={d => d.strategy} itemType="last" orient="right" edgeAt="right" displayFormat={()=>"ETH Stability"} fill="transparent" textFill="rgba(0,0,0,0.95)" rectWidth={95} fontFamily="Lato, sans-serif" fontSize={14} />}
                            {!isTabletOrMobile && <EdgeIndicator yAccessor={d => d.benchmark} itemType="last" orient="right" edgeAt="right" displayFormat={()=>"Hold ETH"} fill="transparent" textFill="rgba(0,0,0,0.6)" rectWidth={80} fontFamily="Lato, sans-serif" fontSize={14} />}
                        </Chart>
                        <Chart id={2} yExtents={[-0.1, 0.1]} height={40} origin={[0, 350]}>
                            <BarSeries yAccessor={d => d.change} fill="#424242" baseAt={20}/>
                            <YAxis axisAt="left" orient="left" ticks={2} showDomain={false} tickFormat={format(".0%")} percentScale={true} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={10} innerTickSize={0} tickStroke="rgba(0,0,0,0.6)"/>
                        </Chart>
                        <CrossHairCursor />
                </ChartCanvas>
            </ChartCanvasContainer>: <Spinner />}
        </ChartContainer>
    </Container>)
}

const GraphMenu = ({ selected, setSelected }) => {
    return (
        <GraphMenuContainer>
            <GraphMenuOption text="All Time" index={0} selected={selected} setSelected={setSelected} />
            <GraphMenuOption text="Year" index={1} selected={selected} setSelected={setSelected} />
            <GraphMenuOption text="3M" index={2} selected={selected} setSelected={setSelected} />
        </GraphMenuContainer>
    )
}

const GraphMenuOption = ({ text, selected, setSelected, index}) => {
    const isSelected = selected == index
    return (
        <>
            {/* <GraphMenuItemInner> */}
                <GraphMenuItemButton isSelected={isSelected} onClick={() => {setSelected(index)}}>{text}</GraphMenuItemButton>
            {/* </GraphMenuItemInner> */}
        </>
    )
}

const ResultItem = ({ title, description, value }) => {
    return <ResultItemContainer>
        <ResultItemHeading>{title}</ResultItemHeading>
        <ResultItemDescription>{description}</ResultItemDescription>
        <ResultItemValue>{value}</ResultItemValue>
    </ResultItemContainer>
}

const MobileLegend = () => {
    return <MobileLegendContainer>
        <LegendItem>
            <LegendBlock color="4682B4"/>
            <LegendText>ETH Stability</LegendText>
        </LegendItem>
        <LegendItem>
            <LegendBlock color="black"/>
            <LegendText>Hold ETH</LegendText>
        </LegendItem>
    </MobileLegendContainer>
}

const Container = styled.div`
width: 100%;
margin-bottom: 2rem;
`

const ChartContainer = styled.div`
touch-action: pan-y;
@media screen and (min-width: 768px) {
    /* margin-left: 2rem; */
}
`

const ChartCanvasContainer = styled.div``

const StatsContainer = styled.div`
margin: 2rem 2rem 1.5rem 1.5rem;
@media screen and (min-width: 768px) {
    margin-left: 6rem;
    margin-right: 0;
}
`

const ResultItemContainer = styled.div`
:not(:first-child) {
    margin-top: 1.5rem;
}
padding-bottom: 0.5rem;
border-bottom: 1.5px solid rgba(0,0,0,0.4);
`

const ResultItemHeading = styled.h3`
margin: 0;
padding: 0;
font-size: ${FONT_SIZE.medium};
font-weight: bold;
text-align: left;
`

const ResultItemDescription = styled(ContentText)`
margin-top: 0.6rem;
color: ${COLORS.dark};
`

const ResultItemValue = styled(ContentText)`
margin-top: 1.5rem;
font-size: 1.375rem;
`

const GraphMenuContainer = styled.div`
display: flex;
width: 100%;
border-bottom: 1px solid rgba(0,0,0,0.2);
margin-bottom: 1rem;
@media screen and (min-width: 768px) {
    width: auto;
    margin-left: 0;
}
`

const GraphMenuItemContainer = styled.div``

const GraphMenuItemInner= styled.div`
width: 70px;
`

const GraphMenuItemButton = styled.button`
background: none;
border: none;
cursor: pointer;
font-size: ${FONT_SIZE.regular};
padding: 0;
color: #0A0A0A;
margin-right: 2rem;
margin-bottom: 0;
:after {
  content: "";
  display: block;
  margin-top: 0.8rem;
  right: 0;
  width: 100%;
  height: 3px;
  background: ${props => props.isSelected ? `rgba(0,0,0, 0.8)`: 'transparent'};
}
`

const MobileLegendContainer = styled.div`
display: flex;
@media screen and (min-width: 768px) {
    margin-left: 2rem;
}
`

const LegendItem = styled.div`
margin-right: 2rem;
margin-bottom: 1rem;
`

const LegendBlock = styled.div`
width: 12px;
height: 12px;
background-color: ${props => props.color};
`

const LegendText = styled(ContentText)`
font-size: ${FONT_SIZE.small};
margin-top: 0.3rem;
`
