import styled from "styled-components/macro"
import { FONT_SIZE } from "./Typography"
import { COLORS } from "./Colors"

const RoundedButton = ({ children, backgroundColor, textColor, ...props }) => {
    return <Button backgroundColor={backgroundColor} textColor={textColor} type="button" {...props}>
        {children}
    </Button>
}

const DarkRoundedButton = ({ children, ...props }) => <RoundedButton backgroundColor={COLORS.dark} textColor={COLORS.white} {...props}>{children}</RoundedButton>
const GrayRoundedButton = ({ children, ...props }) => <RoundedButton backgroundColor={COLORS.gray} textColor={COLORS.white} {...props}>{children}</RoundedButton>
const LightRoundedButton = ({ children, ...props }) => <RoundedButton backgroundColor={COLORS.light} textColor={COLORS.dark} {...props}>{children}</RoundedButton>
const PrimaryRoundedButton = ({ children, ...props }) => <RoundedButton backgroundColor={COLORS.primary} textColor={COLORS.white} {...props}>{children}</RoundedButton>
const PrimaryTransparentButton = ({ children, ...props }) => <RoundedButton backgroundColor='rgba(0,0,0,0)' textColor={COLORS.primary} {...props}>{children}</RoundedButton>

const Button = styled.button`
border-radius: 30px;
border: none;
font-size: ${FONT_SIZE.small};
font-weight: 600;
color: ${props => props.textColor};
background-color: ${props => props.backgroundColor};
padding: 1rem 2rem;
cursor: pointer;
@media screen and (min-width: 768px) {
    padding: 1rem 3rem;
    font-size: ${FONT_SIZE.regular};
}
`

export {RoundedButton, DarkRoundedButton, LightRoundedButton, PrimaryRoundedButton, PrimaryTransparentButton, GrayRoundedButton}