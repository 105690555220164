import styled from "styled-components/macro"
import { ContentText } from "../Typography"
import logo from "../../static/dovish-logo-white.png"
import twitter from "../../static/twitter.png"

export function Footer() {
    return (
        <Container>
            <MainContent>
                <LinksContainer>
                <Column>
                    <ColumnHeading>Products</ColumnHeading>
                    <Link href="/marketplace" target="_blank">Marketplace</Link>
                    <Link href="/dashboard" target="_blank">Dashboard</Link>
                    <Link href="https://swap.dovish.finance" target="_blank">Swap</Link>
                </Column>
                <Column>
                    <ColumnHeading>Developers</ColumnHeading>
                    <Link href="https://docs.dovish.finance" target="_blank">Docs</Link>
                    <Link href="/whitepaper.pdf" target="_blank">Whitepaper</Link>
                    <Link href="https://solidity.finance/audits/DovishWethIndexModule/" target="_blank">Audit</Link>
                </Column>
                <Column>
                    <ColumnHeading>Resources</ColumnHeading>
                    <Link href="/">Terms and Condition</Link>
                </Column>
                <Column>
                    <ColumnHeading>About</ColumnHeading>
                    <Link href="/#about">Dovish</Link>
                    <Link href="/#stability-vault">ETH Stability Vault</Link>
                    <Link href="/#faq">FAQ</Link>
                    <Link href="https://twitter.com/DovishFi" target="_blank"><Twitter src={twitter}/>Twitter</Link>
                </Column>
                </LinksContainer>
                <Logo src={logo} />
                <Subheadline>Crypto yield made simple</Subheadline>
                <Copyright>© Copyright 2023 by Dovish Finance.</Copyright>
                <Disclaimer>{DISCLAIMER_TEXT}</Disclaimer>
            </MainContent>
        </Container>
    )
}

const Container = styled.div`
background-color: #242424;
text-align: left;
`

const LinksContainer = styled.div`
@media screen and (min-width: 768px) {
    display: flex;
}
`

const Column = styled.div`
margin-bottom: 4rem;
@media screen and (min-width: 768px) {
    margin-right: 5rem;
    margin-bottom: 0;
}
`

const ColumnHeading = styled(ContentText)`
color: white;
margin-bottom: 2rem;
font-weight: 800;
`

const Link = styled.a`
display: block;
margin-bottom: 1.2rem;
color: white;
text-align: left;
text-decoration: none;
`

const MainContent = styled.div`
position: relative;
width: calc(100% - 3rem);
max-width: 1340px;
margin-left: auto;
margin-right: auto;
padding: 5rem 0;
@media screen and (min-width: 768px) {
  width: calc(100% - 10rem);
}
`

const Logo = styled.img`
margin-top: 4rem;
height: 25px;
margin-left: -9px;
@media screen and (min-width: 768px) {
    height: 35px;
}
`

const Twitter = styled.img`
height: 15px;
margin-bottom: -2px;
margin-right: 10px;
`

const Subheadline = styled(ContentText)`
margin-top: 1.2rem;
font-size: 1rem;
color: white;
@media screen and (min-width: 768px) {
    font-size: 1.2rem;
    margin-top: 2rem;
}
`

const Copyright = styled(ContentText)`
margin-top: 5rem;
color: white;
@media screen and (min-width: 768px) {
    margin-top: 2rem;
    text-align: right;
}
`

const Disclaimer = styled(ContentText)`
color: #FAFAFA;
margin-top: 5rem;
font-size: 0.92rem;
line-height: 180%;
`

const DISCLAIMER_TEXT = `
This website is intended for informational purposes only. The Products mentioned on this website may not be available to individuals residing in, incorporated or having a registered agent in, the United States, and buying cryptocurrencies or crypto tokens may involve risks. As crypto-assets are a relatively new technological innovation with a limited history, they are highly speculative. Any future regulatory policies or actions may limit the ability to sell, exchange, or use a crypto asset. The price of a crypto asset may be impacted by the transactions of a small number of holders of such crypto asset. There is no guarantee that any token will increase in value.

When considering purchasing the Dovish ETH Stability Vault token or any other related Dovish product, it is crucial to carefully evaluate the risk factors, purchase objectives, fees, expenses, and other relevant information. All Products that are speculative in nature involve a high degree of risk and uncertainty, and there is no guarantee that any token will increase in value.

The Dovish ETH Stability Vault token is an ERC-20 token available for purchase and sale through EVM-compatible chains, and there is no assurance that the token's value will reflect the value of the Dovish ETH Stability Vault net assets. Tokens of any Products may trade at a substantial premium over or a substantial discount to the value of the network's net assets.

Before making any purchase decision regarding any Product, each prospective user must undertake their independent examination and investigation of the Product, including the merits and risks involved in buying the Product, and must base its decision, including a determination of whether the Product would be a suitable purchase for the prospective user, on such examination and investigation and must not rely on Lithium Labs LLC. or the Products in making such decision. Past performance of any Product is not indicative of future results. Prospective users must not construe the contents of this website as legal, tax, investment, or other advice. Each prospective user is urged to consult with its advisors regarding the legal, tax, regulatory, financial, accounting, and similar consequences of investing in any Product, the suitability of the purchase for such user, and other relevant matters concerning a purchase of any Product.

This website contains an overview summary of the constituents of each Product. This website is neither an offer to sell nor a solicitation or recommendation to buy units or shares in any Product or any recommendation of any Product. The summary set forth on this website does not purport to be complete, and it may be qualified either in whole or in part by reference to other documents or sources of information. Please do not place undue reliance on this website. To the extent this website or any Product refers to or otherwise incorporates third-party content, Lithium Labs LLC. is not responsible for any errors or omissions in such content.

The tokens have not been approved or disapproved by the Securities and Exchange Commission, are not registered under the Securities Act of 1933, the Investment Company Act of 1940, or any state securities commission or other regulatory body. Lithium Labs LLC is not registered as an Investment Adviser under the Investment Advisers Act of 1940 and is not registered as a Commodity Pool Operator or Commodity Trading Adviser under the Commodity Exchange Act.

Prospective users of any Product should very carefully consider such risks prior to making any purchase decision. Dovish ETH Stability Vault tokens may always be redeemed for their underlying net assets held in the Dovish ETH Stability Vault by users through their self-custodial crypto wallets on the Dovish website.
`