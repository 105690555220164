import styled from "styled-components/macro"
import { Navbar } from "../components/landing-new/Navbar"
import { Hero } from "../components/landing-new/Hero"
import { MobileMenu } from "../components/landing-new/MobileMenu"
import { useState } from "react"
import { Overview } from "../components/landing-new/Overview"
import { Endorsements } from "../components/landing-new/Endorsements"
import { EndCTA } from "../components/landing-new/EndCTA"
import { Faq } from "../components/landing-new/Faq"
import { VaultHeader } from "../components/landing-new/VaultHeader"
import PriceChart from "../components/landing-new/PriceChart"
import { Footer } from "../components/landing-new/Footer"

export function LandingPage() {
    const [showMenu, setShowMenu] = useState(false);

    return <Container>
        <Navbar setShowMenu={setShowMenu} />
        <MobileMenuHideable show={showMenu} setShowMenu={setShowMenu}/>
        <Hero />
        <Overview />
        <VaultHeader />
        <PriceChart />
        <Endorsements />
        <EndCTA />
        <Faq />
        <Footer />
    </Container>
}

const Container = styled.div`
overflow: auto;
width: 100%;
height: 100%;
`

const MobileMenuHideable = styled(MobileMenu)`
display: ${props=>props.show ? 'block' : 'none'};
`