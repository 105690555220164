import styled from "styled-components/macro"
import { COLORS } from "./Colors"

const FONT_SIZE = {
    extraLarge: '3.75rem',
    large: '2.35em',
    mediumLarge: '1.5em',
    medium: '1.25rem',
    regular: '1.1em',
    mediumSmall: '0.9em',
    small: '0.85em'
}

const SegmentHeading = styled.h2`
  text-align: left;
  font-weight: 700;
  margin: 0;
  padding: 0;
  font-size: ${FONT_SIZE.mediumLarge};

  @media screen and (min-width: 768px) {
    font-size: ${FONT_SIZE.large};
  }
`;

const ContentText = styled.p`
text-align: left;
font-size: ${FONT_SIZE.regular};
margin: 0;
padding: 0;
@media screen and (min-width: 768px) {
  font-size: ${FONT_SIZE.regular};
}
`

const PrimaryLink = styled.a`
font-weight: 700;
color: ${COLORS.primary};
text-decoration: none;
`

export {
    FONT_SIZE, SegmentHeading, ContentText, PrimaryLink
}
