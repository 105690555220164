import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import styled from "styled-components/macro";
import logo from "../static/dovish-logo.png";
import home from "../static/home-black.png";
import homeGreen from "../static/home-green.png";
import marketplace from "../static/marketplace-black.png";
import marketplaceGreen from "../static/marketplace-green.png";
import indicator from "../static/indicator.png";
import indicatorGreen from "../static/indicator-green.png";
import swap from "../static/swap-line.svg";
import chart from "../static/trending-up.svg";
import { ContentText } from "../components/Typography";
import { COLORS } from "../components/Colors";
import { useMediaQuery } from "react-responsive";
import close from "../static/x.svg"

const Container = styled.div`
position: sticky;
flex-shrink: 0;
height: 100vh;
overflow: hidden;
background-color: #FCFCFC;
left: 0;
top: 0;
`

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;

  nav ul {
    list-style-type: none;
    padding: 0;
  }

  nav ul li {
    margin: 10px 0;
  }

  nav ul li a {
    text-decoration: none;
    color: #333;
  }

  @media (max-width: 768px) {
    left: -600px;
    width: 50%;
    z-index: 1000;
    transition: left 0.3s;
  }
`;

const HamburgerMenuIcon = styled.span`
  width: 30px;
  height: 3px;
  background-color: #333;
  display: block;
  margin-bottom: 5px;
  transition: transform 0.3s, opacity 0.3s;
`;

const Logo = styled.img`
  width: 110px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const MobileLogo = styled.img`
margin-top: 1.05rem;
margin-left: 1.5rem;
height: 22px;
`

const NavIcon = styled.img`
  position: relative;
  margin-right: 1rem;
  width: 18px;
  height: 18px;
  z-index: 9999;
`;

const NavLinkText = styled(ContentText)`
color: ${props => props.isSelected ? COLORS.primary : 'black'};
font-weight: ${props => props.isSelected ? 700 : 500};
margin-left: 0.8rem;
`

const NavLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  border-radius: 5px;
`;

const NavLinkA = styled.a`
display: flex;
align-items: center;
margin-bottom: 3rem;
border-radius: 5px;
`

const HamburgerMenuWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 1001;
  }
`;

const HamburgerMenu = ({ isOpen, onClick }) => {
  return (
    <HamburgerMenuWrapper onClick={onClick}>
      <HamburgerMenuIcon
        style={
          isOpen
            ? {
                transform: "translate(3.6px, 3.6px) rotate(45deg)",
                marginBottom: 0,
              }
            : {}
        }
      />
      <HamburgerMenuIcon
        style={isOpen ? { opacity: 0, marginBottom: 0 } : {}}
      />
      <HamburgerMenuIcon
        style={
          isOpen
            ? {
                transform: "translate(3.6px, -3.6px) rotate(-45deg)",
                marginBottom: 0,
              }
            : {}
        }
      />
    </HamburgerMenuWrapper>
  );
};

const Sidebar = ({ showMobileMenu, setShowMobileMenu }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const location = useLocation();

  const isSelected = (path) => (location.pathname + location.hash) == path;

  return (isTabletOrMobile) ? <MobileMenu showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu}/> :<DesktopSidebar isSelected={isSelected}/>;
};

const DesktopSidebar = ({ isSelected }) => {
  return <Container>
  <SidebarWrapper>
    <Logo src={logo} alt="Dovish logo" />
    <Nav>
      <ul>
        <li>
          <Link name="Home" link="/dashboard/#" isSelected={isSelected("/dashboard/")} icon={home} selectedIcon={homeGreen}/>
        </li>
        <li>
          <Link name="Indicators" link="/dashboard/#indicators" isSelected={isSelected("/dashboard/#indicators")} icon={indicator} selectedIcon={indicatorGreen}/>
        </li>
        <li>
          <Link name="Marketplace" link="/marketplace" isSelected={isSelected("/marketplace")} icon={marketplace} selectedIcon={marketplaceGreen}/>
        </li>
        <li>
          <Link name="Swap" href="https://swap.dovish.finance" icon={swap} selectedIcon={swap}/>
        </li>
      </ul>
    </Nav>
  </SidebarWrapper>
  </Container>
}

const Link = ({ name, link, href, icon, selectedIcon, isSelected, toggleMenu }) => {
  const inner = <NavLinkWrapper isSelected={isSelected}>
    <NavIcon src={isSelected ? selectedIcon: icon} alt="Chart icon" />
    <NavLinkText isSelected={isSelected}>{name}</NavLinkText>
  </NavLinkWrapper>

  if (href) {
    return <a href={"https://swap.dovish.finance"} target='_blank'>{inner}</a>
  } else {
    return <HashLink to={link} onClick={toggleMenu}>
      {inner}
    </HashLink>
  }
}

const MobileMenu = ({ showMobileMenu, setShowMobileMenu }) => {
  if (!showMobileMenu) return <></>

  const closeMenu = () => { setShowMobileMenu(false); }

  return <MobileContainer>
        <Header> 
            <MobileLogo src={logo} alt="Logo" />
            <CloseButton onClick={() => {setShowMobileMenu(false)}}/>
        </Header>
        <Content>
            <Item href="/dashboard/#" onClick={closeMenu} className="umami--click--dashboard-nav">Home</Item>
            <Item href="/dashboard/#indicators" onClick={closeMenu} className="umami--click--indicators-nav">Indicators</Item>
            <Item href="/marketplace" onClick={closeMenu} className="umami--click--marketplace-nav">Marketplace</Item>
            <Item href="https://swap.dovish.finance" target='_blank' onClick={closeMenu} className="umami--click--marketplace-swap">Swap</Item>
        </Content>
  </MobileContainer>
}

const Nav = styled.nav`
margin-top: 2rem;
margin-right: 2rem;
`

const MobileContainer = styled.div`
position: fixed;
z-index: 999;
height: 100%;
width: 100%;
left: 0;
top: 0;
background-color: white;
`


const Header = styled.div`
display: flex;
justify-content: space-between;
background-color: ${COLORS.white};
width: 100%;
`

const Content = styled.div`
display: block;
margin-top: 5rem;
margin-left: 1.7rem;
text-align: left;
animation: 0.65s ease-in 0s 1 slideInBottom both;
`


const CloseButton = styled.button`
height: 1rem;
width: 1rem;
margin-top: 1.25rem;
margin-right: 1.3rem;
background-position: center;
background-color: transparent;
background-image: url(${close});
border: none;
cursor: pointer;
`

const Item = styled.a`
display: block;
margin-bottom: 2rem;
text-decoration: none;
color: ${COLORS.dark};
`

export default Sidebar;
