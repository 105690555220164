import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components/macro";
import { ethers, BigNumber } from 'ethers';
import Sidebar from "./Sidebar";
import MarketplaceFooter from "./MarketplaceFooter";
import { Color } from "../utils/Color";
import CustomOracleNavIssuanceModuleABI from "../components/web3/CustomOracleNavIssuanceModule.json";
import SetValuerABI from "../components/web3/SetValuer.json";
import Erc20 from "../components/web3/Erc20.json";
import SetTokenABI from "../components/web3/SetToken.json";
import { useStrategyData } from "../hooks/StrategyDataHook";
import { ConnectedWrapper } from "../components/ConnectedWrapper";
import { useConnectWallet, useSetChain, useWallets } from '@web3-onboard/react';
import StrategyChart from "../components/StrategyChart";
import { ContentText, FONT_SIZE } from "../components/Typography"
import { useMediaQuery } from "react-responsive";
import { Navbar } from "../components/landing-new/Navbar";
import Addresses from "./Addresses.json";
import { useMeasure } from "react-use";
import { DarkRoundedButton } from "../components/Buttons";
import { CHAINS, addChain, setChain } from "../utils/Web3";
import { TransactionStatus } from "../components/TransactionStatus";
import { GeographicalBlock } from "../components/GeographicalBlock";
import { MainContext } from "../Context";


const Marketplace = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [chainInfo, setChain] = useSetChain();
  const context = useContext(MainContext)

  const [isBuySectionVisible, setIsBuySectionVisible] = useState(true);
  const [expectedAmount, setExpectedAmount] = useState(0);
  const [textValue, setTextValue] = useState(0);
  const [toggleSell, setToggleSell] = useState(true);
  const [usdcBalance, setUsdcBalance] = useState(0);
  const [wethBalance, setWethBalance] = useState(0);
  const [setTokenBalance, setSetTokenBalance] = useState(0);
  const [buyCurrency, setBuyCurrency] = useState(null);
  const [addresses, setAddresses] = useState({});
  const strategyData = useStrategyData()
  const connectedWallets = useWallets();
  // use state to save wallet
  const [wallet, setWallet] = useState(connectedWallets[0]);
  const [userAddress, setUserAddress] = useState(null)
  const [usdcAddress, setUsdcAddress] = useState("");
  const [wethAddress, setWethAddress] = useState("");
  const [setToken, setSetToken] = useState(null);
  const [customOracleNavIssuance, setCustomOracleNavIssuance] = useState(null);
  const [setValuer, setSetValuer] = useState(null);
  const [usdcInterface, setUsdcInterface] = useState(null);
  const [wethInterface, setWethInterface] = useState(null);
  const [signer, setSigner] = useState(null);
  const [currTransaction, setCurrTransaction] = useState(null);

  const addTokenToMetamask = async () => {
    if (!window || !window.ethereum) return

    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: "0x784496ff85ff36faee7A623233B0dfeCff94690b", // The address that the token is at.
          symbol: "ESV", // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
          // image: tokenImage, // A string url of the token logo
        },
      },
    });
  }


  useEffect(() => {
        if (connectedWallets.length == 0) return;
        //const wallet = connectedWallets[0]; // arbitrarily select first wallet
        setWallet(connectedWallets[0]);
        setUserAddress(connectedWallets[0].accounts[0].address);
        if (connectedWallets[0].chains[0].id === "0x89") {
            setAddresses(Addresses.polygonMainnet);
        }
    }, [connectedWallets]);

    useEffect(() => { 
    //to clear up input boxes when switching between buy and redeem tabs
        setTextValue(0);
        setExpectedAmount(0);
    }, [toggleSell]);

    useEffect(() => {
        const loadContract = async () => {
            if(typeof addresses !== undefined && wallet){
                setUsdcAddress(addresses.usdcAddress);
                setWethAddress(addresses.wethAddress);
                const provider = new ethers.providers.Web3Provider(wallet.provider, 'any');
                const signer = provider.getSigner(userAddress);
                setSigner(signer);
                let customOracleNavIssuanceContract = new ethers.Contract(addresses.customOracleNavIssuance, CustomOracleNavIssuanceModuleABI, signer);
                setCustomOracleNavIssuance(customOracleNavIssuanceContract);
                let setValuerContract = new ethers.Contract(addresses.setValuer, SetValuerABI, signer);
                setSetValuer(setValuerContract);
                let setTokenContract = new ethers.Contract(addresses.setToken, SetTokenABI, signer);
                setSetToken(setTokenContract);
                let usdcContract = new ethers.Contract(addresses.usdcAddress, Erc20, signer);
                setUsdcInterface(usdcContract);
                let wethContract = new ethers.Contract(addresses.wethAddress, Erc20, signer);
                setWethInterface(wethContract);
                let usdcBalance = await usdcContract.balanceOf(userAddress);
                setUsdcBalance(Number(ethers.utils.formatUnits(usdcBalance, 6)).toFixed(2));
                let wethBalance = await wethContract.balanceOf(userAddress);
                setWethBalance(Number(ethers.utils.formatEther(wethBalance)).toFixed(2));
                setBuyCurrency(addresses.usdcAddress);
            }
        }
        loadContract();
    }, [addresses, wallet]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if(typeof addresses !== undefined && usdcInterface !== null
                && setToken !== null){
                let usdcBalance = await usdcInterface.balanceOf(userAddress);
                let wethBalance = await wethInterface.balanceOf(userAddress);
                setUsdcBalance(Number(ethers.utils.formatUnits(usdcBalance, 6)).toFixed(2));
                setWethBalance(Number(ethers.utils.formatEther(wethBalance)).toFixed(2));
                let setBalance = await setToken.balanceOf(userAddress);
                setSetTokenBalance(Number(ethers.utils.formatEther(setBalance)).toFixed(2));
            }
        }, 2000); // every 2 seconds
    
        return () => clearInterval(interval);
      }, []);


    useEffect(() => {
        async function test(){
            if (!wethInterface) {
                return;
            }
          let wethBalance = await wethInterface.balanceOf(userAddress);

          let setBalance = await setToken.balanceOf(userAddress);
          setSetTokenBalance(Number(ethers.utils.formatEther(setBalance)).toFixed(2));
        }
        test();
    }, [wethInterface])

  const handleBuyCurrencySelect = (e) => {
    setTextValue(0);
    setExpectedAmount(0);
    if (e == "USDC") {
        setBuyCurrency(addresses.usdcAddress);
    } else {
        setBuyCurrency(addresses.wethAddress);
    } //this needs to be passed the address string

  };
  const handleToggle = () => {
    setTextValue(0);
    setExpectedAmount(0);
    setToggleSell(!toggleSell);
  };

  const onNumberInput = (e) => {
    if (e.value.match(/\d+/)) return e.value;
    else return "";
  };

  const onTextChange = async (e) => {
    const regex = /\d+/;
    if (e.target.value && !e.target.value.match(regex)) return;
    setTextValue(e.target.value);
    let num; 
    if (!toggleSell) {
      num = await getExpectedTokenNumber(e.target.value)
    } else {
      num = await getTokenValuation(e.target.value)
    }
    
    if (num == null || num == undefined) return setExpectedAmount(0)
    else setExpectedAmount(parseFloat(num).toFixed(2))
  };

  async function getExpectedTokenNumber(amount) {
    if (!customOracleNavIssuance) {
        console.error("Contract interface not loaded.");
        return;
      }
    if (!amount) return;
      amount = amount.toString();
      let expectedSets;
      if (buyCurrency == usdcAddress) {
        expectedSets = await customOracleNavIssuance.getExpectedSetTokenIssueQuantity(addresses.setToken,
            buyCurrency, ethers.utils.parseUnits(amount, 6));
      } else {
        expectedSets = await customOracleNavIssuance.getExpectedSetTokenIssueQuantity(addresses.setToken,
            buyCurrency, ethers.utils.parseEther(amount));
      }
      return ethers.utils.formatEther(expectedSets);
  }
  async function getTokenValuation(amount) {
    //gets value of set token in USDC
    let reserveAssets = await customOracleNavIssuance.getReserveAssets(addresses.setToken);
    amount = amount.toString();
    let valuation = await setValuer.calculateSetTokenValuation(addresses.setToken, reserveAssets[0]);

    return (ethers.utils.formatEther(valuation) * amount);
  }

  async function buyToken(amount) {
    //amount has to be cast to string!
    amount = amount.toString();
    // let reserveAssets = await customOracleNavIssuance.getReserveAssets(addresses.setToken);
    // function issue(
    //     ISetToken _setToken,
    //     address _reserveAsset,
    //     uint256 _reserveAssetQuantity,
    //     uint256 _minSetTokenReceiveQuantity,
    //     address _to
    // )

    //so that approve only happens if amount is more than allowance
    if (buyCurrency == usdcAddress){
        amount = ethers.utils.parseUnits(amount, 6);
        let allowanceAmount = await usdcInterface.connect(signer).allowance(userAddress, addresses.setToken);
        if(Number(ethers.utils.formatUnits(allowanceAmount, 6)).toFixed(2) < Number(ethers.utils.formatUnits(amount, 6)).toFixed(2)) {
            await usdcInterface.connect(signer).approve(customOracleNavIssuance.address, amount);
        }
    } else {// rn hardcoded to weth, make this programatic in future
        amount = ethers.utils.parseEther(amount);
        let allowanceAmount = wethInterface.connect(signer).allowance(userAddress, addresses.setToken);
        if(Number(ethers.utils.formatUnits(allowanceAmount, 18)).toFixed(2) < Number(amount).toFixed(2)) {
            await wethInterface.connect(signer).approve(customOracleNavIssuance.address, amount);
        }
    }

    const transaction = await customOracleNavIssuance.connect(signer).issue(addresses.setToken,
      buyCurrency, amount, BigNumber.from(0), userAddress, { gasLimit: 500000 });

    setCurrTransaction(transaction);
    
    await new Promise(r => setTimeout(r, 1000));
    await addTokenToMetamask();
    //TO DO: SET USER ACCT FROM MOTHER COMPONENT
    //TODO: RESERVE CURRENCY NEEDS TO BE CALLED FROM SET TOKEN TOO AND PASSED IN AS PROPS
  }

  async function redeemToken(amount) {
    // ISetToken _setToken,
    //   address _reserveAsset,
    //   uint256 _setTokenQuantity,
    //   uint256 _minReserveReceiveQuantity,
    //   address _to
    amount = amount.toString();
    let reserveAssets = await customOracleNavIssuance.getReserveAssets(addresses.setToken);
    const transaction = await customOracleNavIssuance.connect(signer).redeem(addresses.setToken, reserveAssets[0],
      ethers.utils.parseEther(amount), BigNumber.from(0), wallet.accounts[0].address);
    setCurrTransaction(transaction)
  }
  const [ref, {width: elWidth}] = useMeasure()
  const isValidWallet = wallet && (chainInfo.connectedChain.id == '0x89')

  return (
    <Container>
      <GeographicalBlock />
      <TransactionStatus transaction={currTransaction}/>
      {isTabletOrMobile && <Navbar setShowMenu={setShowMobileMenu}/>}
      <Sidebar showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} />
      <ConnectedWrapper chainData={CHAINS.polygon}>
      <MarketplaceContainer>
        <MarketplaceWrapper>
          <MarketplaceHeader>ETH Stability Vault <strong>[DEPRECATED]</strong></MarketplaceHeader>
          <MarketplaceContent>
            <LeftSection ref={ref}>
              <SectionText>Invest in higher, safer returns with the ETH Stability Vault, Dovish's flagship product. Our vault profitably trades between ETH and stablecoins over the long term, offering higher returns and lower risks than just holding ETH.</SectionText>
              <SectionText>This vault has been temporarily deprecated by the Dovish team to focus on our Polygon zkevm DEX instead. Look forward to our vaults being brought there soon. Check out our pools at <a href="https://swap.dovish.finance">swap.dovish.finance</a>.</SectionText>
              {/* <StrategyChart data={strategyData} parentWidth={elWidth} /> */}
              {/* <CardContainer>
                <Card>
                  <h2>APR</h2>
                  <p>Annual Percentage Return</p>
                  <span>{strategyData && strategyData.metrics.Strategy['1Y']}</span>
                </Card>
                <Card>
                  <h2>Sharpe Ratio</h2>
                  <p>Risk adjusted return</p>
                  <span>{strategyData && strategyData.metrics.Strategy['Sharpe']}</span>
                </Card>
                <Card>
                  <h2>3M</h2>
                  <p>3-month Return</p>
                  <span>{strategyData && strategyData.metrics.Strategy['3M']}</span>
                </Card>
              </CardContainer> */}
            </LeftSection>
            <RightSection>
              <PurchaseContainer>
                {!isValidWallet && <NotConnectedPurchase chainData={CHAINS.polygon} />}
                <PurchaseContent>
                  <PurchaseMenu>
                    <ButtonContainer>
                        <MenuButton isActive={!toggleSell} onClick={() => setToggleSell(false)}>
                            Buy
                        </MenuButton>
                        <MenuButton isActive={toggleSell} onClick={() => setToggleSell(true)}>
                            Redeem
                        </MenuButton>
                        </ButtonContainer>
                  </PurchaseMenu>
                  {toggleSell ?
                  <Sell setBalance={setTokenBalance} onTextChange={onTextChange} textValue={textValue} expectedAmount={expectedAmount} wethAddress={wethAddress} usdcAddress={usdcAddress}/>
                  : <Buy buyCurrency={buyCurrency} usdcBalance={usdcBalance} wethBalance={wethBalance} toggleSell={toggleSell} handleBuyCurrencySelect={handleBuyCurrencySelect} onTextChange={onTextChange} textValue={textValue} expectedAmount={expectedAmount} wethAddress={wethAddress} usdcAddress={usdcAddress} />}
                </PurchaseContent>

                {/* </Modal> */}
                {!toggleSell ? (
                  <BuyButton >Buy <strong>[DEPRECATED]</strong></BuyButton>
                  // <BuyButton onClick={() => buyToken(textValue)} >Buy [DEPRECATED]</BuyButton>
                ) : (
                  <SellButton onClick={() => redeemToken(textValue)}>
                    Redeem
                  </SellButton>
                )}
                <NoteText>
                    Your wallet will ask you to approve the transaction with Dovish's smart contracts
                </NoteText>
              </PurchaseContainer>
            </RightSection>
          </MarketplaceContent>
        </MarketplaceWrapper>
        <MarketplaceFooter />
      </MarketplaceContainer>
      </ConnectedWrapper>
    </Container>
  );
};

const Buy = ({ usdcBalance, wethBalance, toggleSell, handleBuyCurrencySelect, onTextChange, textValue, expectedAmount, usdcAddress, wethAddress }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('USDC');

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
    handleBuyCurrencySelect(event.target.value);
  };

  return (
    <>
      <PurchaseInputContainer>
        <FromContainer>
          <Subtitle>From</Subtitle>
          {selectedCurrency === 'USDC' ? (
            <BalanceText>Balance: {usdcBalance} USDC</BalanceText>
          ) : (
            <BalanceText>Balance: {wethBalance} WETH</BalanceText>
          )}
        </FromContainer>

        <AmountInputContainer bold={true}>
          <CustomSelect
            value={selectedCurrency}
            onChange={!toggleSell && handleCurrencyChange}
            size="small"
          >
            <CustomOption value="USDC">USDC</CustomOption>
            <CustomOption value="WETH">WETH</CustomOption>
          </CustomSelect>
          <CustomTextField onChange={onTextChange} value={textValue} type="number" />
        </AmountInputContainer>
      </PurchaseInputContainer>

      <PurchaseInfoContainer>
        <FromContainer>
          <Subtitle>To</Subtitle>
        </FromContainer>
        <AmountInputContainer>
          <TokenName>ESV</TokenName>
          <ToTextDisplay>{expectedAmount}</ToTextDisplay>
        </AmountInputContainer>
        <EstimateText>Estimated before slippage</EstimateText>
      </PurchaseInfoContainer>
    </>
  );
};

const Sell = ({ setBalance, onTextChange, textValue, expectedAmount, usdcAddress, wethAddress}) => {
    return <>
        <PurchaseInputContainer>
            <FromContainer>
                <Subtitle>From</Subtitle>
                <BalanceText>
                    Balance: {setBalance} ESV
                </BalanceText>
            </FromContainer>

            <AmountInputContainer bold={true}>                
                <TokenName>ESV</TokenName>
                <CustomTextField
                onChange={onTextChange}
                value={textValue}
                type="number"
                />
            </AmountInputContainer>
            </PurchaseInputContainer>
            <PurchaseInfoContainer>
            <FromContainer>
                <Subtitle>To</Subtitle>
            </FromContainer>
            <AmountInputContainer>
                <TokenName>USDC</TokenName>
                <ToTextDisplay>{expectedAmount}</ToTextDisplay>
            </AmountInputContainer>
            <EstimateText>
                    Estimated before slippage
            </EstimateText>
            </PurchaseInfoContainer>
    </>
}

const NotConnectedPurchase = ({ chainData }) => {
  const [{ wallet }, connect] = useConnectWallet()
  const [chainInfo] = useSetChain()

  const connectWallet = async () => {
    await connect();
    if (chainInfo.chains.find(e => e.id != chainData.id)) { // add chain
      await addChain(chainData);
    }
    // switch to chain
    await setChain(chainData.id)
}
  return <NotConnectedContainer>
    <NotConnectedText>Polygon wallet not connected</NotConnectedText>
    <NotConnectedButton onClick={connectWallet}>Connect</NotConnectedButton>
  </NotConnectedContainer>
}

const MarketplaceContainer = styled.div`
  flex: 1;
  margin-left: 0;
  width: 100%;
  height: fit-content;
  @media (min-width: 768px) {
    width: auto;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  @media (min-width: 768px) {
    margin-right: 1rem;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1;
  margin-right: 10px;

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  span {
    font-weight: bold;
  }
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const RightSection = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  @media (max-width: 1023px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;


const Switch = styled.div`
  display: flex;
  position: relative;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 10px;
  width: 100%;
`;

const TokenName = styled.p`
  text-align: left;
  font-weight: 700;
  margin-left: 1.5rem;
  height: fit-content;
`;
const Subtitle = styled.p`
  text-align: left;
  margin: 0;
  font-size: ${FONT_SIZE.regular};
  color: #2A2A2A;
  font-weight: 700;
`;
const FromContainer = styled.div`
  display: flex;
`;

const BalanceText = styled.p`
  flex: 1;
  text-align: right;
  margin: 0;
  font-size: 0.9em;
`;
const EstimateText = styled.p`
flex: 1;
text-align: right;
margin: 0;
margin-top: 1rem;
font-size: 0.8em;
font-weight: 400;
color: #2A2A2A;
`;

const NoteText = styled.p`
text-align: left;
width: calc(100% - 4rem);
margin: 0 2rem;
margin-top: 0.25rem;
font-size: 0.8em;
color: #2A2A2A;
margin-bottom: 3rem;
`;


const BuyButton = styled.button`
  background-color: grey;
  color: white;
  border-radius: 8px;
  padding: 1rem 0;
  width: calc(100% - 4rem);
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin: 1rem 2rem;
  margin-top: 2rem;
`;

const SellButton = styled.button`
  background-color: black;
  color: white;
  border-radius: 8px;
  padding: 1rem 0;
  width: calc(100% - 4rem);
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin: 1rem 2rem;
  margin-top: 2rem;
`;

const MarketplaceWrapper = styled.div`
width: calc(100% - 3rem);
text-align: left;
margin-top: 0;
padding: 0 1.5rem;
@media screen and (min-width: 768px) {
    width: auto;
    padding: 2rem;
}
`;

const MarketplaceContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;
const MarketplaceHeader = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  padding-top: 2rem;
`;

const PurchaseContainer = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(220, 220, 220);
  border-radius: 15px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  color: rgb(20, 20, 20);
  width: 100%;
  margin-top: 2rem;
  @media (min-width: 1024px) {
    margin-top: 0;
    width: 23rem;
  }
`;
const PurchaseContent = styled.div`
  width: 100%;
`;

const PurchaseMenu = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PurchaseInfoContainer = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 0 0 10px 10px;
  padding: 1rem 2rem;
`;

const PurchaseInputContainer = styled.div`
  margin-top: 1rem;
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  border-radius: 10px 10px 0 0;
`;


const CustomOption = styled.option`
  font-weight: 700;
  margin-left: 20px;
`;

const CustomSelect = styled.select`
  height: 4rem;
  margin: 0;
  margin-left: 1.5rem;
  padding: 0;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 1.05em;
  text-align: left;
  border: none;
  background-color: transparent;
`;

const AmountInputContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.bold ? '#B4B4B4': '#DEDEDE'};
  border-radius: 10px;
  height: 4.2rem;
  margin-top: 1rem;
  align-items: center;
  /* display: flex;
  margin-top: 1rem;
  background: transparent;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  color: rgb(20, 20, 20); */
`;

const CustomTextField = styled.input`
  flex: 1;
  height: 100%;
  min-width: 0;
  padding-right: 1.5rem;
  text-align: right;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  font-size: ${FONT_SIZE.regular};
  margin-left: 1rem;
  background: transparent;
  /* flex: 1;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 10rem !important;
  text-align: right;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  border: none;
  background-color: transparent;
  
  font-weight: 700;
  font-size: 1.05em; */
`;

const ToTextDisplay = styled.p`
  flex: 1;
  text-align: right;
  margin-right: 1.5rem;
  font-size: ${FONT_SIZE.regular};
`;

const Container = styled.div`
display: flex;
height: 100%;
width: 100%;
overflow: auto;
flex-direction: column;
@media screen and (min-width: 768px) {
  flex-direction: row;
}

`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
  margin: 0 2rem; 
`;

const Button = styled.button`
  border: none;
  padding: 1rem 3rem;
  border-radius: 32px;
  font-weight: bold;
  cursor: pointer;
`;

const MenuButton = styled(Button)`
  background-color: ${props => (!props.isActive ? "transparent" : "black")};
  color: ${props => (!props.isActive ? "black" : "white")};
  font-size: 0.9em;
  margin-top: 10px;
  width: 9rem;
  padding: 1rem 0;
  text-align: center;
`;

const RedeemButton = styled(Button)`
  background-color: ${props => (!props.isActive ? "transparent" : "black")};
  color: ${props => (!props.isActive ? "black" : "white")};
  font-size: 0.9em;
  margin-top: 10px;
`;
const SectionText = styled.p`
padding-top: 0;
font-weight: 400;
line-height: 190%;
font-size: ${FONT_SIZE.medium};
margin-bottom: 4rem;
max-width: 90%;
`;

const NotConnectedContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: absolute;
top: 0;
left: 0;
backdrop-filter: blur(8px);
border-radius: 15px;
`

const NotConnectedText = styled(ContentText)`
font-weight: 800;
`

const NotConnectedButton = styled(DarkRoundedButton)`
height: fit-content;
margin-top: 2rem;
flex-grow: 0;
`

export default Marketplace;
