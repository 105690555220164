import styled from "styled-components/macro"
import { ContentText } from "../Typography"
import { DarkRoundedButton } from "../Buttons"
import { useInView } from 'react-intersection-observer';

export function EndCTA() {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 1 });

    return <Container>
        <MainContent>
            <MainDescription ref={ref}>
            Upgrade your crypto investments today.
            </MainDescription>
            <StartButton forwardedAs="a" href="/marketplace" inView={inView} className="umami--click--start-button">Get Started</StartButton>
        </MainContent>
    </Container>
}

const Container = styled.div`
padding: 2rem 0;
text-align: center;
background-image: linear-gradient(to bottom right, #EEEDFD, #D2FBF1);
@media screen and (min-width: 768px) {
    padding: 5rem 0;
}
`
const MainContent = styled.div`
position: relative;
width: calc(100% - 5rem);
max-width: 1340px;
margin-left: auto;
margin-right: auto;
@media screen and (min-width: 768px) {
  width: calc(100% - 10rem);
}
`

const StartButton = styled(DarkRoundedButton)`
display: inline-block;
text-decoration: none;
margin-top: 3rem;
opacity: 0;
animation: ${props=>props.inView && `0.3s ease-in 0s 1 slideInBottom both`};
@media screen and (min-width: 768px) {
    margin-top: 4rem;
}
`

const MainDescription = styled(ContentText)`
margin-top: 1.5rem;
font-size: 1.6rem;
font-weight: 400;
color: #080808;
text-align: center;
@media screen and (min-width: 768px) {
    font-size: 1.875rem;
}
`
