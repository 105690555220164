import { useContext } from "react";
import styled from "styled-components/macro";
import { DispatchContext, MainContext } from "../Context";
import { DarkRoundedButton, RoundedButton } from "./Buttons";
import { ContentText, SegmentHeading } from "./Typography";
import { Link } from "react-router-dom";


export function GeographicalBlock() {
    const context = useContext(MainContext)
    const dispatch = useContext(DispatchContext)
  
    if (context.notUsa) return <></>
  
    return <GeographicalBlockContainer>
      <GeographicalMessageContainer>
        <Content>
            <Title>
                Not available to US residents
            </Title>
            <GeographicalMessage>
            The ETH Stability Vault is not available to residents of the United States of America. By continuing, you confirm that you are not a resident of the United States of America.
            </GeographicalMessage>
            <ButtonContainer>
                <BackLink to="/dashboard">
                    <BackButton>Back to dashboard</BackButton>
                </BackLink>
                <ConfirmButton onClick={()=>{dispatch({type: 'agree_not_usa'})}}>Continue</ConfirmButton>
            </ButtonContainer>
        </Content>
      </GeographicalMessageContainer>
    </GeographicalBlockContainer>
}

const GeographicalBlockContainer = styled.div`
position: absolute;
z-index: 9999;
width: 100%;
height: 100%;
left: 0;
top: 0;
backdrop-filter: blur(12px);
background-color: rgba(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
`

const GeographicalMessageContainer = styled.div`
position: relative;
background-color: #222;
border-radius: 15px;
width: 90%;
height: 90%;
@media screen and (min-width: 768px) {
  width: 70%;
  height: 70%;
}
`

const Content = styled.div`
width: calc(100% - 4rem);
height: calc(100% - 4rem);
padding: 2rem;
display: flex;
flex-direction: column;
justify-content: space-between;

@media screen and (min-width: 768px) {
    width: calc(100% - 8rem);
    height: calc(100% - 8rem);
    padding: 4rem;
}
`

const Title = styled(SegmentHeading)`
color: white;
margin-top: 2rem;
`

const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
@media screen and (min-width: 768px) {
    flex-direction: row;
}
`

const ConfirmButton = styled(RoundedButton)`
font-size: 0.8rem;
background-color: #F7FAFC;
border: 1px solid #2B2B2B;
padding: 1rem;
width: 100%;
font-weight: bold;
margin-top: 1rem;
@media screen and (min-width: 768px) {
    width: 45%;
    max-width: 20rem;
    margin-left: 1rem;
    margin-top: 0;
    border-radius: 20px;
}
`

const BackLink = styled(Link)`
width: 100%;
@media screen and (min-width: 768px) {
    width: 45%;
    max-width: 20rem;
}
`

const BackButton = styled(RoundedButton)`
font-size: 0.8rem;
background-color: #F7FAFC;
border: 1px solid #2B2B2B;
padding: 1rem;
width: 100%;
font-weight: bold;
@media screen and (min-width: 768px) {
    margin-right: 1rem;
    border-radius: 20px;
}
`


const GeographicalMessage = styled(ContentText)`
margin-right: 2rem;
color: white;
@media screen and (max-width: 768px) {
    margin-right: 1rem;
}
`
