import styled from "styled-components/macro"
import { ContentText, FONT_SIZE, SegmentHeading } from "../Typography"
import { COLORS } from "../Colors"

export function VaultHeader() {
    return <Container>
        <Anchor id="stability-vault"/>
        <Heading>ETH Stability Vault</Heading>
        <LiveBox>
          <LiveText>Polygon</LiveText>
          <LiveDot />
        </LiveBox>
        <Description>Invest in higher, safer returns with the ETH Stability Vault, Dovish's flagship product. Our vault profitably trades between ETH and stablecoins over the long term, offering higher returns and lower risks than just holding ETH.</Description>
    </Container>
}

const Container = styled.div`
position: relative;
max-width: 1340px;
margin-left: auto;
margin-right: auto;
background: linear-gradient(180deg, rgba(238, 237, 253, 0.4) 0%, rgba(238, 237, 253, 0) 100%);
border-radius: 50px 50px 0px 0px;
padding-bottom: 5rem;
@media screen and (min-width: 768px) {
  width: calc(100% - 10rem);
}
`

const Anchor = styled.span`
position: relative;
top: -8rem;
`

const Heading = styled(SegmentHeading)`
font-size: 2rem;
font-weight: 600;
padding-top: 4rem;
margin-left: 1.5rem;
@media screen and (min-width: 768px) {
  margin-left: 4rem;
  padding-top: 6rem;
  font-size: 3.5rem;
}
`

const Description = styled(ContentText)`
margin-left: 1.5rem;
margin-top: 4rem;
font-size: ${FONT_SIZE.regular};
max-width: 80%;
font-weight: 400;
line-height: 160%;
@media screen and (min-width: 768px) {
  margin-left: 4rem;
  width: 70ch;
  font-size: ${FONT_SIZE.medium};
}
`

const LiveBox = styled.div`
display: flex;
margin-top: 2rem;
margin-left: 1.5rem;
background-color: 0A0A0A;
width: fit-content;
padding: 1rem 1.5rem;
border-radius: 40px;
align-items: center;
@media screen and (min-width: 768px) {
  margin-left: 4rem;
}
`

const LiveDot = styled.div`
margin-left: 1rem;
width: 14px;
height: 14px;
background-color: ${COLORS.primaryBright};
border-radius: 7px;
`

const LiveText = styled(ContentText)`
color: white;
font-weight: 800;
font-size: ${FONT_SIZE.small};
@media screen and (min-width: 768px) {
  font-size: ${FONT_SIZE.regular};
}
`