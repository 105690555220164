import styled from "styled-components/macro"
import { COLORS } from "../Colors"
import logo from "../../static/dovish-logo.png"
import close from "../../static/x.svg"
import { GrayRoundedButton } from "../Buttons"


export function MobileMenu({ className, setShowMenu }) {
    const closeMenu = () => { setShowMenu(false); }

    return <Container className={className}>
        <Header>
            <Logo src={logo} alt="Logo" />
            <CloseButton onClick={() => {setShowMenu(false)}}/>
        </Header>
        <Content>
            <Item href="#stability-vault" onClick={closeMenu} className="umami--click--stability-nav">Stability Vault</Item>
            <Item href="https://swap.dovish.finance" className="umami--click--swap-nav">Swap</Item>
            <Item href="/#faq" onClick={closeMenu} className="umami--click--faq-nav">FAQ</Item>
            <Item href="https://docs.dovish.finance" onClick={closeMenu} className="umami--click--docs-nav">Docs</Item>
        </Content>
        <StartButton forwardedAs="a" href="/marketplace" className="umami--click--start-button">Get Started</StartButton>
    </Container>
}

const Container = styled.div`
position: fixed;
display: block;
background-color: ${COLORS.white};
z-index: 99;
left: 0;
top: 0;
height: 100%;
width: 100%;
`

const Header = styled.div`
display: flex;
justify-content: space-between;
background-color: ${COLORS.white};
width: 100%;
`

const Content = styled.div`
display: block;
margin-top: 3rem;
margin-left: 1.7rem;
text-align: left;
animation: 0.65s ease-in 0s 1 slideInBottom both;
`

const Logo = styled.img`
margin-top: 1.05rem;
margin-left: 1.5rem;
height: 22px;
`

const CloseButton = styled.button`
height: 1rem;
width: 1rem;
margin-top: 1.25rem;
margin-right: 1.3rem;
background-position: center;
background-color: transparent;
background-image: url(${close});
border: none;
cursor: pointer;
`

const Item = styled.a`
display: block;
margin-bottom: 1.5rem;
text-decoration: none;
color: ${COLORS.dark};
`

const StartButton = styled(GrayRoundedButton)`
display: inline-block;
text-decoration: none;
position: absolute;
width: auto;
bottom: 4em;
left: 50%;
transform: translateX(-50%);
animation: 0.5s ease-in 0.25s 1 fadeIn both;
`

