import React from 'react';
import styled from 'styled-components';
import { DarkRoundedButton } from '../components/Buttons';
import { Link } from 'react-router-dom';
import { FONT_SIZE } from '../components/Typography';

const MarketplaceFooter = () => {
  return (
    <FooterWrapper>
      <FooterTitle>Looking for insights on your portfolio?</FooterTitle>
      <Link to="/dashboard">
        <LaunchButton>
          Launch Dashboard
        </LaunchButton>
      </Link>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.6) -14.67%, rgba(255, 255, 255, 0) 91.34%), radial-gradient(64.65% 84.71% at 39.38% 34.68%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), conic-gradient(from 203.21deg at 40.68% 110.4%, #EEEDFD 0deg, #D2FBF1 360deg);
  padding: 3rem 0;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
`;


const FooterTitle = styled.h2`
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: ${FONT_SIZE.regular};
  @media (min-width: 768px) {
    font-size: ${FONT_SIZE.medium};
  }
`;

const LaunchButton = styled(DarkRoundedButton)`
  /* display: block;
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  width: 200px;
  margin: 0 auto; */
  display: inline-block;
  text-decoration: none;
  margin-top: 1.5rem;
  font-size: ${FONT_SIZE.small};
`;

export default MarketplaceFooter;