import styled from "styled-components/macro"
import polygon from "../../static/polygon.png"
import solidity from "../../static/solidity.png"
import { ContentText, PrimaryLink, SegmentHeading } from "../Typography"

export function Endorsements() {
    return <Container>
        <MainContent>
            <Heading>
            Dovish is built on secure and trusted technology
            </Heading>
            <EndorsementContainer>
                <EndorsementItem
                icon={polygon}
                title="Polygon Labs"
                description="Dovish Finance leverages the high transaction throughput on Polygon's chain to execute and rebalance the vaults efficiently."
                />
                <EndorsementItem
                icon={solidity}
                title="Solidity Finance"
                description={
                    <>
                    Our smart contract has been audited by Solidity Finance, a leading smart contract auditor with over 1500+ contracts audited. A link to our audit report can be found <PrimaryLink href="https://solidity.finance/audits/DovishWethIndexModule/">here</PrimaryLink>.
                    </>}
                />
            </EndorsementContainer>
        </MainContent>
    </Container>
}

const EndorsementItem = ({title, description, icon}) => {
    return <ItemContainer>
        <Icon src={icon} />
        <ItemContent>
            <ItemHeading>{title}</ItemHeading>
            <ItemDescription>{description}</ItemDescription>
        </ItemContent>
    </ItemContainer>
}

const Container = styled.div`
padding: 2.5rem 0;
@media screen and (min-width: 768px) {
    padding: 6rem 0;
}
`

const MainContent = styled.div`
position: relative;
max-width: 1340px;
margin-left: auto;
margin-right: auto;
border-radius: 40px;
background-color: #343434;
justify-content: space-between;
@media screen and (min-width: 768px) {
    display: flex;
    width: calc(100% - 10rem);
    padding: 2rem 0;
}
`

const Heading = styled(SegmentHeading)`
color: white;
font-size: 2rem;
margin-left: 2rem;
padding-top: 3rem;
width: 15ch;
max-width: 80%;
line-height: 150%;
@media screen and (min-width: 768px) {
    padding-top: 5rem;
    font-size: 2.75rem;
    margin-left: 5rem;
}
`

const EndorsementContainer = styled.div`
margin: 5rem 8rem 0rem 0rem;
padding-bottom: 3rem;
width: 90%;
text-align: left;
@media screen and (min-width: 768px) {
    width: 40%;
}
`

const ItemContainer = styled.div`
:not(:last-child) {
    margin-bottom: 3rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 5rem;
    }
}
@media screen and (min-width: 768px) {
    display: flex;
}
`

const Icon = styled.img`
height: 50px;
margin-left: 2rem;
margin-bottom: 1.5rem;
@media screen and (min-width: 768px) {
    margin-top: 0.4rem;
    margin-bottom: 0;
    height: 70px;
}
`

const ItemContent = styled.div`
margin-left: 2rem;
@media screen and (min-width: 768px) {
    margin-left: 3rem;
}
`

const ItemHeading = styled(SegmentHeading)`
color: white;
margin-bottom: 1.5rem;
@media screen and (min-width: 768px) {
}
`

const ItemDescription = styled(ContentText)`
margin-top: 1rem;
line-height: 170%;
color: white;
`