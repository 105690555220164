import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries, BarSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { useAssetPrice } from '../hooks/AssetPrice';
import {
	CrossHairCursor,
	MouseCoordinateX,
	MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";
import { scaleTime } from "d3-scale";
import { timeFormat } from "d3-time-format";
import { format } from "d3-format";
import { useMediaQuery } from 'react-responsive';
import useWindowDimensions from '../hooks/WindowDimensions';
import { ContentText, FONT_SIZE } from '../components/Typography';
import dayjs from 'dayjs';
import { useIndicator } from '../hooks/IndicatorHook';
import { Spinner } from '../components/Spinner';
import up from "../static/up.png"
import down from "../static/down.png"
import { useMeasure } from "react-use";


const IndicatorsWrapper = styled.div`
  background: linear-gradient(180deg, rgba(238, 237, 253, 0.5) 0%, rgba(253, 253, 253, 0) 8.01%);
  padding: 1.5rem;
  border-radius: 10px;
  text-align: left;

  @media screen and (min-width: 768px) {
    margin: 0;
    padding: 3rem;
  }
`;

const IndicatorsHeader = styled.h2`
  font-size: 1.875rem;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background: none;
  border: none;
  font-size: ${FONT_SIZE.small};
  margin-top: 1rem;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  cursor: pointer;

  :after {
    content: "";
    display: block;
    margin-top: 0.8rem;
    right: 0;
    width: 100%;
    height: 3px;
    background: ${props => props.active ? `rgba(0,0,0, 0.8)`: 'transparent'};
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;
const Tabs = styled.div`
  display: flex;
  margin-bottom: 2rem;
  color: black;
  background-color: #F7FAFC;
  padding: 0 0.5rem;
  border-radius: 10px;
  margin-left: -0.5rem;

  @media (min-width: 768px) {
    padding: 0 1rem;
  }

  @media screen and (max-width: 1023px) {
    justify-content:space-around;
  }

  @media screen and (min-width: 1024px) {
    ${Tab} {
      margin-right: 40px;
    }
  }
`;

const TabContent = styled.div`
  display: flex;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  width: min-content;
  margin-right: 2rem;
  @media screen and (max-width: 1023px) {
    padding: 0;
    margin-right: 0;
  }
`;

const RightSection = styled.div`
  flex: 1;
  padding: 10px;
`;

const Title = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;

  @media screen and (max-width: 1023px) {
    margin-top: 2rem;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 2rem;
`;

const Card = styled.div`
  /* background-color: #f0f0f0; */
  border-radius: 10px;
  margin-top: 2rem;
  margin-right: 2rem;
  margin-bottom: 10px;
  flex-basis: calc(33.33% - 10px);
  box-sizing: border-box;

  &:last-child {
    margin-right: 0;
  }
`;

const CardTitle = styled.h4`
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  @media screen and (max-width: 1023px) {

  }
`;

const CardText = styled.p`
  font-size: 0.8rem;
  font-weight: 300;
  margin-bottom: 5px;
`;

const CardReturnContainer = styled.div`
  display: flex;
  align-items: center;
`

const CardReturn = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Anchor = styled.span`
position: relative;
margin-top: 10rem;
`

const dovishHawkishCards = [
  { title: 'Year', text: 'Change in one year'},
  { title: 'Month', text: 'Change in one month'},
  { title: 'Week', text: 'Change in one week'}
];

const loanHealthCards = [
  { title: 'Year', text: 'Change in one year'},
  { title: 'Month', text: 'Change in one month'},
  { title: 'Week', text: 'Change in one week'}
];

const volatilityCards = [
  { title: 'Year', text: 'Change in one year'},
  { title: 'Month', text: 'Change in one month'},
  { title: 'Week', text: 'Change in one week'}
];

const indicatorDescription = {
  dovishHawkish: "The Dovish-Hawkish Index is a tool we developed to gauge the overall market sentiment. The lower the index, the more likely it is that the markets might be headed for a crash in asset prices, and vice versa.",
  loanHealth: "The Loan Health Index is developed to track the overall health of loans in the crypto market. A lower index indicates lesser liquidity, and a higher chance of liquidations across the market, which typically lead to a drop in crypto prices.",
  volatility: "The Volatility Index is modelled on the most major crypto coins by volume. A lower index indicates lower volatility, and vice versa. Prices are likely to shoot up or drop drastically at higher indexes, so trade with caution at those times!"
}

const indicatorNames = {
  dovishHawkish: "Dovish-Hawkish Index",
  loanHealth: "Loan Health Index",
  volatility: "Volatility Index"
}

const Indicators = () => {
  const [activeTab, setActiveTab] = useState('dovishHawkish');

  const dovishHawkishCards = [
    { title: 'Card 1', text: 'Dovish/Hawkish Card Text 1', returnVal: '3% Return' },
    { title: 'Card 2', text: 'Dovish/Hawkish Card Text 2', returnVal: '5% Return' },
    { title: 'Card 3', text: 'Dovish/Hawkish Card Text 3', returnVal: '7% Return' }
  ];

  const loanHealthCards = [
    { title: 'Card 1', text: 'Loan Health Card Text 1', returnVal: '2% Return' },
    { title: 'Card 2', text: 'Loan Health Card Text 2', returnVal: '4% Return' },
    { title: 'Card 3', text: 'Loan Health Card Text 3', returnVal: '6% Return' }
  ];

  const volatilityCards = [
    { title: 'Card 1', text: 'Volatility Card Text 1', returnVal: '1% Return' },
    { title: 'Card 2', text: 'Volatility Card Text 2', returnVal: '8% Return' },
    { title: 'Card 3', text: 'Volatility Card Text 3', returnVal: '10% Return' }
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'dovishHawkish':
        return <TabItem indicator="fear_greed_index_year" tabName="dovishHawkish"/>
      case 'loanHealth':
        return <TabItem indicator="loan_index_year" tabName="loanHealth"/>
      case 'volatility':
        return <TabItem indicator="volatility_index_year" tabName="volatility"/>
      default:
        return null;
    }
  };
  return (
    <IndicatorsWrapper >
      <Anchor id="indicators" />
      <IndicatorsHeader>Indicators</IndicatorsHeader>
      <Tabs>
        <Tab active={activeTab === 'dovishHawkish'} onClick={() => setActiveTab('dovishHawkish')}>
          Dovish/Hawkish
        </Tab>
        <Tab active={activeTab === 'loanHealth'} onClick={() => setActiveTab('loanHealth')}>
          Loan Health
        </Tab>
        <Tab active={activeTab === 'volatility'} onClick={() => setActiveTab('volatility')}>
          Volatility
        </Tab>
      </Tabs>
      {renderTabContent()}
    </IndicatorsWrapper>
  );
};

const TabItem = ({ indicator, tabName }) => {
  const indicatorData = useIndicator(indicator)
  const data = useMemo(() => {
      if (!indicatorData) return null;
      return indicatorData.map((e, i) => {
          return {
              date: new Date(Date.parse(e[0])),
              close: e[1],
              change: (i != 0) ? (indicatorData[i][1] - indicatorData[i-1][1]) / indicatorData[i-1][1] : 0
          }
      })
  }, [indicatorData])

  const dataSlices = useMemo(() => {
      if (!data) return;

      const indexes = [
          data.findIndex(item => item.date >= dayjs().subtract(365, 'days')),
          data.findIndex(item => item.date >= dayjs().subtract(30, 'days')),
          data.findIndex(item => item.date >= dayjs().subtract(7, 'days'))
      ]
      return indexes.map((e) => data.slice(e, -1))
  }, [data])

  const [ref, {width: elWidth}] = useMeasure()

  return <TabContent>
    <TabLeftSection tabName={tabName} data={dataSlices}/>
    <RightSection ref={ref}>
      <Graph data={dataSlices} parentWidth={elWidth}/>
    </RightSection>
  </TabContent>
}

const TabLeftSection = ({ tabName, data }) => {
  const returns = useMemo(() => {
    if (!data) return null

    return data.map(d => (d[d.length-1].close - d[0].close) /d[0].close * 100)
  }, [data])

  let cardData = [];
  switch (tabName) {
    case 'dovishHawkish':
      cardData = dovishHawkishCards;
      break;
    case 'loanHealth':
      cardData = loanHealthCards;
      break;
    case 'volatility':
      cardData = volatilityCards;
      break;
    default:
      break;
  }
  return (
    <LeftSection>
      <Title>{indicatorNames[tabName]}</Title>
      <TabText>{indicatorDescription[tabName]}</TabText>
      <CardsWrapper>
        {cardData.map(({ title, text }, i) => (
          <Card key={title}>
            <CardTitle>{title}</CardTitle>
            <CardText>{text}</CardText>
            <CardReturnContainer>
              <CardReturn>{returns && `${returns[i].toFixed(1)}%`}</CardReturn>
              <IndicatorImg src={returns && returns[i] >= 0 ? up: down}/>
            </CardReturnContainer>
          </Card>
        ))}
      </CardsWrapper>
    </LeftSection>
  );
};

const Graph = ({ data, parentWidth }) => {
  const [selectedGraphIndex, setSeletedGraphIndex] = useState(0)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const { width } = useWindowDimensions(); 

  const chartContainerCallback = useCallback((ref) => {
    if (!ref) return
    ref.addEventListener('wheel', (e)=>{
      if (!e.ctrlKey) e.stopPropagation()
      else e.preventDefault()
    }, { passive: false });
  })

  const filteredData = data ? data[selectedGraphIndex] : null
  
  return <Container>
      <GraphMenu setSelected={setSeletedGraphIndex} selected={selectedGraphIndex} />
      <ChartContainer ref={chartContainerCallback}>
        {filteredData ? <ChartCanvas width={isTabletOrMobile ? width - 64: parentWidth - 20} height={450}
                margin={{ left: 40, right: 0, top:10, bottom: 30 }}
                data={filteredData}
                xScale={scaleTime()}
                xAccessor={d => d.date}
                ratio={2}
                type="hybrid"
                seriesName="ETH"
                clamp={true}
                pointsPerPxThreshold={1000}
                xExtents={[filteredData[0].date, filteredData[filteredData.length-1].date]}
                >
                <Chart id={1} yExtents={d=>[0, 100]} height={300}>
                    <XAxis axisAt="bottom" orient="bottom" ticks={4} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={11} tickStroke="rgba(0,0,0,0.6)" stroke="rgba(0,0,0,0.6)"/>
                    <YAxis axisAt="left" orient="left" ticks={7} innerTickSize={0} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={11} tickStroke="rgba(0,0,0,0.6)"/>
                    <MouseCoordinateX
                        at="bottom"
                        orient="bottom"
                        fontSize={11}
                        displayFormat={timeFormat("%Y-%m-%d")} />
                    <MouseCoordinateY
                        at="left"
                        orient="left"
                        fontSize={11}
                        arrowWidth={0}
                        displayFormat={format(">.2f")} />
                    <AreaSeries yAccessor={d => d.close} strokeWidth={1} fill="#F7FAFC" stroke="#OAOAOA"/>
                </Chart>
                <Chart id={2} yExtents={[-0.1, 0.1]} height={40} origin={[0, 350]}>
                    <BarSeries yAccessor={d => d.change} fill="#OAOAOA" baseAt={20}/>
                    <YAxis axisAt="left" orient="left" ticks={2} showDomain={false} tickFormat={format(".0%")} percentScale={true} fontFamily="Lato, sans-serif" fontWeight={700} fontSize={10} innerTickSize={0} tickStroke="rgba(0,0,0,0.6)"/>
                </Chart>
                <CrossHairCursor />
        </ChartCanvas>: <Spinner/>}
      </ChartContainer>
  </Container>
}

const GraphMenu = ({ selected, setSelected }) => {
  return (
      <GraphMenuContainer>
          <GraphMenuOption text="Year" index={0} selected={selected} setSelected={setSelected} />
          <GraphMenuOption text="Month" index={1} selected={selected} setSelected={setSelected} />
          <GraphMenuOption text="Week" index={2} selected={selected} setSelected={setSelected} />
      </GraphMenuContainer>
  )
}

const GraphMenuOption = ({ text, selected, setSelected, index}) => {
  const isSelected = selected == index
  return (
      <GraphMenuItemButton isSelected={isSelected} onClick={() => {setSelected(index)}}>{text}</GraphMenuItemButton>
  )
}

const Container = styled.div`
@media screen and (min-width: 768px) {
margin-left: 1rem;
}
`


const GraphMenuContainer = styled.div`
display: flex;
width: calc(100% - 3rem);
border-bottom: 1px solid rgba(0,0,0,0.2);
margin-bottom: 1rem;
@media screen and (min-width: 768px) {
    width: calc(100% - 2rem);
}
`


const GraphMenuItemButton = styled.button`
background: none;
border: none;
cursor: pointer;
font-size: ${FONT_SIZE.regular};
padding: 0;
color: #0A0A0A;
margin-right: 2rem;
margin-bottom: 0;
:after {
  content: "";
  display: block;
  margin-top: 0.8rem;
  right: 0;
  width: 100%;
  height: 3px;
  background: ${props => props.isSelected ? `rgba(0,0,0, 0.8)`: 'transparent'};
}
`

const ChartContainer = styled.div``

const IndicatorImg = styled.img`
width: 24px;
height: 24px;
margin-left: 5px;
`

const TabText = styled(ContentText)`
margin-right: 1rem;
padding-top: 2rem;

@media screen and (min-width: 768px) {
  min-width: 40ch;
}
`

export default Indicators;