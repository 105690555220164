import { useConnectWallet, useSetChain } from '@web3-onboard/react'
import React, { Children, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { addChain, setChain } from '../utils/Web3';

export function ConnectedWrapper({ children, chainData }) {
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
    const [chainInfo] = useSetChain();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const connectWallet = async () => {
        await connect();
        if (chainInfo.chains.find(e => e.id != chainData.id)) { // add chain
          await addChain(chainData);
        }
        // switch to chain
        await setChain(chainData.id)
    }

    const ConnectComponent = <Container>
        <Hint>Connect your wallet to enable Dovish's advanced portfolio analytics</Hint>
        <ConnectButton href="#" onClick={() => { connectWallet() }}>Connect Wallet</ConnectButton>
    </Container>

    useEffect(() => {
        if (!wallet && !isTabletOrMobile) connectWallet();
    }, [])


    return <>{
        Children.map(children, child =>React.cloneElement(child, {
            wallet,
            connecting,
            connectWallet,
            connectComponent: ConnectComponent
        }))
    }</>
    // if ((!connecting && wallet) || isTabletOrMobile) {
    //     return <>{children}</>
    // } else if (connecting) {
    //     return <></>
    // } else {
    //     return (
            
    //     )
    // }
}

const Container = styled.div`
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
align-items: center;
justify-content: center;
`

const ConnectButton = styled.a`
padding: 0.5rem;
font-weight: bold;
border: 1px solid rgba(0,0,0,0.6);
color: rgba(0,0,0,0.8);
border-radius: 5px;
background: none;
box-shadow: none;
text-decoration: none;
`

const Hint = styled.p`
color: rgba(0, 0, 0, 0.8);
font-weight: bold;
`