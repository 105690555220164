import { useState, useEffect } from "react";

export function useIndicator(indicator) {
    const [data, setData] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            const res = await fetch(`https://dovish.finance/api/${indicator}/`);
            const data = await res.json();
            setData(data.map(d => [d.time, d.value]))
        }
        
        fetchData();
    }, [indicator])

    return data;
}
