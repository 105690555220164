import styled from "styled-components/macro";
import { Footer } from "../components/Footer";
import { NavBar } from "../components/landing/NavBar";
import headerArtwork from "../static/header.png"
import { Color } from "../utils/Color";
import  {WalletLanding} from '../components/Profile';
import { GeofenceInformation } from "../components/geofence/GeofenceInformation";

export function GeofencePage() {
    return (
        
        <LandingPageContainer>
            <NavBar />
            <Header>
                <HeaderImage src={headerArtwork} height="100%"/>
                <HeaderTextContainer>
                    <Title>Not available</Title> 
                    <Subtitle>in your region</Subtitle>
                    <Button href="/indicators">Go Back</Button>
                    <WalletLanding />
                </HeaderTextContainer>
            </Header>
            <GeofenceInformation />
            <Footer />
        </LandingPageContainer>
    )
}

const LandingPageContainer = styled.div`
position: absolute;
width: 100%;
overflow: hidden;
`

const Header = styled.div`
position: relative;
height: 70vh;
@media screen and (max-width: 768px) {
    height: 42vh;
}
`

const Button = styled.a`
width: fit-content;
padding: 0.5rem;
text-decoration: none;
color: rgba(0, 0, 0, 0.8);
font-weight: bold;
border: 1px solid rgba(0,0,0,0.8);
border-radius: 10px;
`

const HeaderTextContainer = styled.div`
padding-top: 2rem;
margin-left: 2rem;
display: flex;
flex-direction: column;
justify-content: center;
height: 100%;
width: fit-content;
text-align: left;
@media screen and (max-width: 768px) {
    margin-left: 1.5rem;
}
`

const HeaderImage = styled.img`
position: absolute;
right: 0;
bottom: 0;
@media screen and (max-width: 768px) {
    display: none;
}
/* height: 70vh;
width: 50vh; */
`;

const Title = styled.h1`
z-index: 999;
font-size: 4em;
margin: 0;
font-weight: normal;
@media screen and (max-width: 768px) {
    font-size: 2.5em;
}
`
const Subtitle = styled.h1`
z-index: 999;
font-size: 4em;
color: ${Color.DOVISH_PRIMARY};
margin: 0;
font-weight: normal;
margin-bottom: 2rem;
@media screen and (max-width: 768px) {
    font-size: 2.5em;
}
`
