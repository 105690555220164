const CHAINS = {
    polygon: {
        id: '0x89',
        namespace: 'evm',
        token: 'MATIC',
        label: 'Polygon Mainnet',
        rpcUrl: 'https://polygon-rpc.com',
        decimals: 18
    }
}

const setChain = async (chainId) => {
    if (window.ethereum) {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId }],
        });
    }
}

const addChain = async ( chainData ) => {
    if (window.ethereum) {
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: chainData.id,
                rpcUrls: [chainData.rpcUrl],
                chainName: chainData.label,
                nativeCurrency: {
                    name: chainData.token,
                    symbol: chainData.token,
                    decimals: chainData.decimals
                }
            }]
        });
    }
}

export { CHAINS, setChain, addChain }
