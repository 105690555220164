import styled from "styled-components/macro";
import logo from "../../static/dovish-logo.png"
import { Color } from "../../utils/Color";
import { useMediaQuery } from "react-responsive";

export function NavBar() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return (
        <NavBarContainer>
            <Icon src={logo} alt="Logo" />
            <ButtonContainer>
                {!isTabletOrMobile &&
                    <>
                        <NavBarButton href="#stability-fund">ETH Stability Vault</NavBarButton>
                        <NavBarButton href="#dovish-hawkish">Dovish-Hawkish Index</NavBarButton>
                        <NavBarButton href="#faq">FAQ</NavBarButton>
                        <NavBarButton href="https://docs.dovish.finance">Docs</NavBarButton>
                        <NavBarButton href="dashboard">App</NavBarButton>
                    </>
                }
            </ButtonContainer>
        </NavBarContainer>
    )
}

const NavBarContainer = styled.div`
position: fixed;
top: 0;
padding-top: 1.5rem;
padding-bottom: 1.5rem;
display: flex;
align-items: center;
padding-left: 2.2rem;
z-index: 9999;
width: 100%;
background-color: white;
box-shadow: 0 15px 15px -15px rgba(0,0,0,0.1);

@media screen and (max-width: 768px) {
    padding-left: 1.6rem;
}
`

const Icon = styled.img`
width: 90px;
`

const ButtonContainer = styled.div`
width: calc(100% - 90px - 3rem);
text-align: right;
`

const NavBarButton = styled.a`
margin-right: 3rem;
color: ${Color.DOVISH_PRIMARY};
text-align: center;
font-weight: bold;
text-decoration: none;
`;
