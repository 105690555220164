import styled from 'styled-components/macro';
import { COLORS } from './Colors';

export function Spinner({ className }) {
    return <Spin className={className}>
        <Inner1/>
        <Inner2/>
    </Spin>
}


const Spin = styled.div`
width: 40px;
height: 40px;

position: relative;
margin: 100px auto;

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
`
const Inner1 = styled.div`
width: 100%;
height: 100%;
border-radius: 50%;
background-color: ${COLORS.primary};
opacity: 0.6;
position: absolute;
top: 0;
left: 0;

-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
animation: sk-bounce 2.0s infinite ease-in-out;
`

const Inner2 = styled(Inner1)`
-webkit-animation-delay: -1.0s;
animation-delay: -1.0s;
`