import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ethers, BigNumber } from 'ethers';
import { Spinner } from "./Spinner";
import { ContentText } from "./Typography";
import check from "../static/check.svg"
import alert from "../static/alert-circle.svg"


const Status = {
    NONE: 0,
    LOADING: 1,
    SUCCESS: 2,
    ERROR: 3,
}

export function TransactionStatus({ transaction }) {
    const [status, setStatus] = useState(Status.NONE)
    const [showStatus, setShowStatus] = useState(false);

    useEffect(() => {
        if (!transaction) return;

        setStatus(Status.LOADING);
        setShowStatus(true);

        const getReceipt = async () => {
            const receipt = await transaction.wait()
            if (receipt.status == 1) {
                setStatus(Status.SUCCESS)
            } else {
                setStatus(Status.ERROR)
            }
        }

        getReceipt()
    }, [transaction])

    useEffect(() => {
        const hideStatus = async () => {
            await new Promise(r => setTimeout(r, 1000 * 60)); // 1 minute
            setShowStatus(false)
        }

        if (status == Status.SUCCESS) {
            hideStatus()
        }
    }, [status])

    const getContent = () => {
        switch(status) {
            case Status.LOADING:
                return <>
                <CustomSpinner />
                <StatusText>Transaction loading</StatusText>
                </>
            case Status.ERROR:
                return <>
                <CustomImg src={alert} />
                <StatusText>Transaction error</StatusText>
                </>
            case Status.SUCCESS:
                return <>
                <CustomImg src={check} />
                <StatusText>Transaction complete</StatusText>
                </>
        }
    }


    return <Container showStatus={showStatus}>
        {getContent()}
    </Container>
}

const Container = styled.div`
position: absolute;
display: flex;
opacity: ${props => props.showStatus ? 1 : 0};
-webkit-transition: opacity 600ms, visibility 600ms;
transition: opacity 600ms, visibility 600ms;
z-index: ${props => props.showStatus ? 999 : -1};
top: 3.5rem;
width: calc(100% - 4rem);
left: 0;
background-color: #F7FAFC;
align-items: center;
padding: 1rem 2rem;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

@media screen and (min-width: 768px) {
    padding: 1rem 2rem;
    width: fit-content;
    left: auto;
    top: 1rem;
    right: 2rem;
    border-radius: 10px;
}
`

const CustomSpinner = styled(Spinner)`
margin: 0;
width: 20px;
height: 20px;
`

const CustomImg = styled.img`
width: 20px;
height: 20px;
`

const StatusText = styled(ContentText)`
margin-top: -3px;
margin-left: 2rem;
font-size: 1rem;
font-weight: 700;
`