import React, { useState } from 'react';
import styled from 'styled-components/macro';
import DashboardPriceGraphs from './DashboardPriceGraphs';
import AssetAllocation from './AssetAllocation';
import Indicators from './Indicators';
import DashboardFooter from './DashboardFooter';
import Sidebar from './Sidebar';
import { ConnectedWrapper } from "../components/ConnectedWrapper";
import { useMediaQuery } from 'react-responsive';
import { Navbar } from '../components/landing-new/Navbar';
import { CHAINS } from '../utils/Web3';


const Container = styled.div`
display: flex;
flex-direction: column;
@media screen and (min-width: 768px) {
  flex-direction: row;
}
`

const DashboardWrapper = styled.div`
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 3rem;
  text-align: left;
  @media screen and (min-width: 768px) {
    padding: 2rem;
  }
`;

const DashboardHeader = styled.h1`
  width: fit-content;
  font-size: 1.875rem;
  margin-bottom: 20px;
  text-align: left;
  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`;

const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const DashboardComponent = styled.div`
  flex: 1;
`;

const DashboardMain = styled.div`
  overflow: auto;
  width: 100%;
  flex: 1;
`;

const Dashboard = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Container>
      {isTabletOrMobile && <Navbar setShowMenu={setShowMobileMenu}/>}
      <Sidebar showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} />
      <DashboardMain>
        <DashboardWrapper>
          <DashboardHeader>My Dashboard</DashboardHeader>
          <DashboardContent>
          <ConnectedWrapper chainData={CHAINS.polygon}>
            <DashboardComponent>
              <DashboardPriceGraphs />
            </DashboardComponent>
            {/* <DashboardComponent>
              <AssetAllocation />
            </DashboardComponent> */}
            </ConnectedWrapper>
          </DashboardContent>
        </DashboardWrapper>
        <Indicators id="indicators"/>
        <DashboardFooter />
      </DashboardMain>
    </Container>
  );
};

export default Dashboard;